.addFood {
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0;
  width: 100%;
  background: white;
}

@keyframes slideIn {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  85% {
    transform: translateY(1%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.addFood {
  animation: slideIn 0.3s ease-in-out;
  animation-fill-mode: both;
}