@media screen and (max-width: 1023px) {
    .subNavigation {
        position: relative;
        z-index: 1;
        max-width: 38rem;
        height: 5rem;
        margin: 0 auto 2.4rem;
        font-size: 1.6rem;

        div {
            position: absolute;
            width: 100%;
            border: 1px #e5e9eb solid;
            border-radius: 6px;
            overflow: hidden;
            background-color: #fff;
        }

        button {
            position: relative;
            display: block;
            width: 100%;
            height: 5rem;
            padding: 0 1.2rem;
            line-height: 4.8rem;
            text-align: left;

            &:after {
                content: '';
                position: absolute;
                top: 2.2rem;
                right: 1.2rem;
                width: 0;
                height: 0;
                border-left: 0.6rem solid transparent;
                border-right: 0.6rem solid transparent;
                border-top: 0.6rem solid #3fb867;
            }
        }

        ul {
            max-height: 27.5rem;
            overflow-y: scroll;

            a {
                display: block;
                height: 4.8rem;
                padding: 0 1.2rem;
                border-top: 1px #e5e9eb solid;
                line-height: 4.8rem;
                color: #002538;

                &.active {
                    font-weight: 600;
                }
            }
        }

        &[data-isopen="true"] {
            div {
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
            }

            button:after {
                transform: rotate(180deg);
            }

            ul {
                display: block;
            }
        }

        &[data-isopen="false"] {
            button {
                color: #002538;
            }

            ul {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .subNavigation {
        width: calc(60% - 12px);
        margin-bottom: 36px;
        text-align: center;

        button {
            display: none;
        }

        li {
            display: inline-block;
            margin: 0 8px;
        }

        a {
            display: block;
            padding: 0 18px;
            border: 1px #e5e9eb solid;
            border-radius: 36px;
            font-size: 14px;
            line-height: 36px;

            @media (hover:hover) {
                &:hover {
                    border-color: #002538;
                }
            }

            &.active {
                border-color: #002538;
                color: #002538;
            }
        }
    }
}

@media screen and (min-width: 1260px) {
    .subNavigation {
        width: calc(60% - 18px);
    }
}
