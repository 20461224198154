.container {
  margin: 0.2rem 0 1.4rem 0;
}

.image {
  max-width: 2.4rem;
  margin-right: 0.4rem;

  &:last-child {
    margin-right: 0;
  }
}
