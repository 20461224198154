.wrapper {
  display: flex;
}
.content {
  margin-right: 164px;
  color: black;
}
.title {
  margin-bottom: 10px;
  line-height: 1.33;
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}
.text {
  margin-bottom: 12px;
  line-height: 1.5;
}
.text a {
  font-weight: 700;
  text-decoration: underline;
}
.alert {
  padding: 18px;
  border-radius: 5px;
  border: 1px solid #ff982a;
  line-height: 1.5;
  font-weight: 700;
  background: #ffeedb;
}
.sidebar {
  flex: none;
  width: 312px;
  padding: 24px 16px;
  border-radius: 5px;
  background: #ff982a;
}
.sidebar span {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.sidebar h3 {
  margin-bottom: 154px;
  line-height: 1.18;
  font-size: 32px;
  font-weight: 900;
}
.sidebar a {
  display: block;
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  background: white url(../images/back-arrow.png) left 16px center no-repeat;
  background-size: 22px;
  color: black;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .content {
    margin-right: 92px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    display: block;
  }
  .content {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .sidebar {
    width: 100%;
  }
}
