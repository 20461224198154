.ingredientForm {
    input[type="text"],
    input[type="number"],
    select {
        &:focus {
            border-color: #002538;
            box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
        }
    }

    button:focus {
        box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
    }
}