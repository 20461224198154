.course {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.course .noTime {
  width: 48px;
  display: inline-block;

}
.course time {
  width: 48px;
  padding: 5px 2px;
  border-radius: 5px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;

  background: #f6f6f6;
}



.courseDetails{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 0;
  max-height: 800px;
  height: calc(100% - 50px);

  h2{
    border-bottom: none;
    margin-bottom: 0;
  }
  > div{
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

  }

  .courseSignup{
    display: grid;
    grid-template-columns: auto;
    gap: 6px;

    margin-bottom: 35px;
    border-bottom: 1px solid #e5e9eb;
    padding-bottom: 5px;
    button  {

      display: block;
      padding: 0 3.6rem ;

      border-radius: 2.4rem;
      line-height: 4.8rem;
      font-size: 1.6rem;
      font-weight: 600;

      margin: 18px 0 1.4rem 0;
      text-align: center;
      background: linear-gradient(101deg, #036775, #9eef89);
      color: white;
      &:hover{
        background: #2ea369;
      }
    }

  }

  transition: max-height 0.5s ease-in-out;

  box-shadow: 0 -10px 10px rgba(182, 182, 182, 0.75);
  background-color: white;
  z-index: 100;
  h3{
    margin-left: 0 !important;
  margin-bottom: 8px !important;
  line-height: 22px !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  border: none !important;
  }

}


@media screen and (min-width: 768px) {
  .instructorAndType{
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 40px;
  }
  .instructorHeader{
    grid-template-columns: 100px auto !important;
    > * {
      grid-column: 2 !important;
    }

    img{
      grid-column: 1 !important;
    }
  }


  }

.instructorAndType{


  > div{
      min-height: 18.8rem;

      margin-bottom: 2rem;

      border-radius: 0.5rem;
      font-weight: 400;
      transition: all 3 ease-in-out;
      background-color: white;
      grid-column-gap: 2rem;
      grid-row-gap: 12px;


  }


}
.instructorHeader {
  position: relative;
  min-height: 90px;
  padding-left: 112px;
  margin-bottom: 2rem;
  p {
    margin-top: -4px;
    color: #777;
    font-size: 16px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    grid-column: 2 ;
    grid-row: 1 /3;
  }

}
.instructorBio{
  font-size: 18px;
  ul {
    padding-left: 1.2em;
    margin-bottom: 1em;
    margin-top: 1em;
  > li{
    list-style: disc;
  }
  p{
    margin-top: 1em;
  }
}


}
.closeButton{
  position: absolute;
  right: 5px;
  top: 5px;
  margin: 5px;
  padding: 6px;
  border-radius: 50%;
  border: 2px solid black;
  width: 33px;
  height: 33px;
  font-size: 11px;
  text-align: center;
  background-color: white;
}


.courseDetails > div{
  padding: 60px 10px 10px 10px;
}

.card {
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  width: 305px;

  padding: 14px;
  margin-left: 14px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  cursor: pointer;
  transition: background 0.3s ease;
  background-color: white;
}

.card[data-open="true"]{
  background: rgba(#2ea369, 0.3);


}

.card[data-open="true"]:hover{
  background: rgba(#2ea369, 0.3) !important;


}

.instructor{
  margin-top: 6px;
  img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

}

.card[data-status="available"] {
  border: 1px #2ea369 solid;
}
.card[data-status="available"]:hover {
  background: rgba(#2ea369, 0.14);
}
.card[data-status="few"] {
  border: 1px #ff982a solid;
  padding-bottom: 2.5em;
}
.card[data-status="few"]:hover {
  background: rgba(#ff982a, 0.14);
}
.card[data-status="full"] {
  border: 1px #f24e4e solid;
  color: rgba(#121212, 0.5);
  pointer-events: none;
}
.card[data-status="few"]:after,
.card[data-status="full"]:after {
  content: "Nesten fullt";
  position: absolute;
  bottom: 14px;
  right: 14px;
  font-weight: 600;
  color: #ff982a;
}
.card[data-status="full"]:after {
  content: "Fullt";
  bottom: 17px;
  color: #f24e4e;
}
.card[data-status="full"] p,
.card[data-status="full"] a {
   display: none;
}
.card h4 {
  grid-row-start: 1;
  grid-column-start: 1;
  line-height: 22px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 1rem;
  }

}
.card[data-status="full"] h4 {
  padding-right: 130px;
}
.card span.time {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 2;
  grid-column-end: 2;
  display: flex;
  flex-direction: column;
  line-height: 22px;
  text-align: right;
}


.card[data-status="full"] span {
  right: 48px;
}
.card p {
  margin-top: 2px;
  line-height: 20px;
  max-width: 190px;
}
.courseLocation {
  grid-row-start: 1;
  grid-column-start: 1;
  display: inline-block;
  margin-top: 28px;
  padding-left: 21px;
  text-decoration: underline;
  background: url(../images/marker.svg) left top no-repeat;
  background-size: 15px;
}
.courseLocation[data-type="web"] {
  padding-left: 25px;
  background: url(../images/web.svg) left center no-repeat;
  background-size: 16px;
}
.courseLocation[data-type="video"] {
  padding-left: 26px;
  background: url(../images/video.svg) left center no-repeat;
  background-size: 18px;
}
.courseLocation[data-multiple="false"] {
  grid-column-start: 1;
  grid-column-end: 3;
}
.card[data-status="few"] a[data-type] {
  max-width: 190px;
}
.courseLocation[data-type="web"],
.courseLocation[data-type="video"] {
  text-decoration: none;
  pointer-events: none;
}

@media screen and (max-width: 450px) {
  .card {
    width: calc(100% - 62px);
  }
}

.main {
  display: flex;
  max-width: calc(1230px + 72px);
  padding: 0 36px;
  margin: 0 auto;
}
.sidebar {
  width: 268px;
  min-height: calc(100vh - 345px);
  padding: 46px 38px 46px 0;
  border-right: 1px #ccd3d7 solid;
}
.overview {
  flex: 1;
  max-width: calc(100% - 268px);
  padding: 40px 0 46px 45px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 450px) {
  .main {
    flex-direction: column;
    max-width: 100%;
    padding: 0 16px;
  }
  .sidebar {
    width: 100%;
    min-height: auto;
    padding-right: 0;
    padding-bottom: 0;
    border: 0;
  }
  .overview {
    max-width: 100%;
    padding-left: 0;
  }
}
