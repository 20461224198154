:global(body.paywall) {
  overflow: hidden;
}
.paywall {
  position: fixed;
  z-index: 10;
  top: 78px; right: 0; bottom: 0; left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255,250,247,0.8);
  backdrop-filter: blur(6px);
}
.wrapper {
  max-width: 898px;
}
.heading h1 {
  line-height: 48px;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 48px;
  color: #002538;
}
.gold h2 {
  margin-bottom: 20px;
  line-height: 42px;
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  color: #252525;
}
.gold h2 span {
  font-weight: 300;
}
.gold ul {
  margin-bottom: 50px;
  font-weight: 400;
  color: #252525;
}
.gold li {
  padding-left: 26px;
  margin-bottom: 5px;
  line-height: 22px;
  background: url(./checkmark.svg) top 5px left no-repeat;
  background-size: 12px;
}
.option {
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px 64px 16px 27px;
  margin-bottom: 23px;
  border-radius: 38px;
  font-weight: 400;
  background: #002538;
  color: white;
}
.option[data-popular]::before {
  content: "Mest populær";
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 18px;
  border-radius: 13px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(270deg, #F24E4E 0%, #F89C46 100%);
  color: white;
}
.option:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 29px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: rgba(white, 0.25);
}
.option[data-checked="true"]:after {
  background: #2ea369 url(./option-checked.svg) center center no-repeat;
  background-size: 25px;
}
.option span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 44px;
  text-align: left;
}
.option strong {
  display: block;
}
.optionName {
  width: 65%;
  font-size: 14px;
}
.optionName strong {
  margin: 2px 0 3px 0;
  font-size: 18px;
  font-weight: 700;
}
.optionPrice {
  width: 35%;
  font-size: 12px;
}
.optionPrice strong {
  font-size: 24px;
  font-weight: 900;
}
.submit {
  display: block;
  width: 100%;
  height: 50px;
  margin-top: 30px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 700;
  background: #2ea369;
  color: white;
}
.submit:disabled {
  background: #E8E8E8;
  color: rgba(#072941, 0.5);
  pointer-events: none;
}
.testimonial {
  max-width: 320px;
}
.testimonial h4,
.testimonial h5 {
  line-height: 20px;
  font-size: 15px;
  font-weight: 800;
  color: #002538;
}
.testimonial h4 {
  text-transform: uppercase;
  opacity: 0.5;
}
.testimonial h5 {
  margin-bottom: 4px;
}
.testimonial blockquote {
  margin-bottom: 31px;
  line-height: 22px;
  font-weight: 400;
  font-style: italic;
  color: #252525;
}
.disclaimer {
  line-height: 14px;
  font-size: 10px;
  font-weight: 400;
  color: #252525;
}
.rating {
  display: flex;
  margin-bottom: 14px;
}
.rating span {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 3px;
  background: url(./star.svg) center center no-repeat;
  background-size: 12px;
}


.appInfo {
  
  
}
.appPreTitle{
  font-size: 180%
}
.appInfo h2{
  font-size: 200%;
  font-weight: 800;
}

.appInfo li{
  font-size: 150%;
  margin: 1rem 0;
  list-style-type: disc;
  list-style-position: inside;
}


@media screen and (max-width: 1023px) {
  .heading h1 {
    line-height: 38px;
    font-size: 32px;
    margin-bottom: 38px;
  }
}

@media screen and (min-width: 768px) {
  .wrapper {
    display: grid;
    grid-template-columns: 320px 65px 335px;
    grid-template-rows: auto auto 0;
  }
  .heading {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .gold {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
  .appInfo,
  .testimonial {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: span 2;
  }
}

@media screen and (min-width: 1024px) {
  .wrapper {
    grid-template-columns: 437px 124px 335px;
  }
}

@media screen and (max-width: 767px) {
  .paywall {
    overflow-y: scroll;
    top: 58px;
  }
  .wrapper {
    position: relative;
    top: 232px;
    max-width: 375px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .gold {
    margin-bottom: 43px;
  }
  .testimonial {
    margin-bottom: 140px;
  }
}