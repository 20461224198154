.dailyReviewSection {
    padding-bottom: 2rem;
    margin-bottom: 1.8rem;
    text-align: center;
    border-bottom: 1px #e9e9e9 solid;

    &:last-child {
        padding-bottom: 0.6rem;
        margin-bottom: 0rem;
        border-bottom: 0;
    }

    h3 {
        margin-bottom: 1.2rem;
        font: 2.1rem/1.5 Times, serif;
        color: #000000;
    }

    i {
        margin: 0 2rem;
        font-size: 3.2rem;
        color: #e5e9eb;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                color: #8b8b8b;
            }
        }
    }

    input {
        display: none;
    }

    input:checked + i {
        color: #002538;
    }
}

.dailyReviewRatings {
    padding-bottom: 3.2rem;
}

@media screen and (min-width: 768px) {
    .dailyReviewRatings {
        display: flex;
        justify-content: space-between;
    }

    .dailyReviewSection {
        width: 33.33%;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
        border-left: 1px #e9e9e9 solid;

        &:first-child {
            border: 0;
        }
    }
}

.dailyReviewComments {
    padding: 1.6rem;
    border-radius: 0.6rem;
    background: #f6f6f6;

    @media screen and (min-width: 768px) {
        padding: 2.4rem;
    }

    textarea {
        display: block;
        width: 100%;
        height: 8.4rem;
        padding: 0.6rem 1.2rem;
        margin-bottom: 0.8rem;
        border: 1px #e5e9eb solid;
        border-radius: 0.6rem;
        font-size: 16px;
        line-height: 1.5;
        color: #000000;

        &:focus {
            border-color: #002538;
            box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
        }
    }

    button {
        width: 100%;
        height: 4.8rem;
        border-radius: 0.6rem;
        background: #002538;
        color: white;
    }
}
