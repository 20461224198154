.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4.8rem;
  height: 4.8rem;
  margin: -2.4rem 0 0 -2.4rem; 

}

.loadingInline{
  display: inline-block;
  position: relative;
  left: 50%;
  width: 4.8rem;
  height: 4.8rem;
  margin: 2.4rem 0 0 -2.4rem; 

}

.loadingInline,
.loading {
  display: block;
  
  &:after {
    content: ' ';
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    margin: 0.8rem;
    border-radius: 50%;
    border: 0.3rem solid #002538;
    border-color: #002538 transparent #002538 transparent;
    animation: loading 1.2s linear infinite; }
  
  @keyframes loading {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } 
  }
}
  