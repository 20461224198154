
.heading{
  font-size: 3.2rem;
  line-height: 4.3rem;
  margin-top: 4rem;
  margin-bottom: 2rem;

}
.tools{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;

}

.toolSection{
  width: calc(50% - 4rem);
  margin: 2rem;
  border: 1px solid #CCD3D7;
  padding: 3rem 4rem;
  border-radius: 8px;
}
.toolSection img{
  margin-bottom: 1rem;
}
.toolSection h2{
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 2rem;
}

.toolSection a{
  text-decoration: underline;
  
}

.toolSection ul{
  list-style:disc;
  list-style-position: inside;
  
  li{
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .tools{
    margin: 0 0;
  }
  .toolSection{
    width: 100%;
    margin: 2rem 0
  }
}