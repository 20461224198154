.wrapper {
  max-width: 697px;
  padding-top: 24px;
}
.text {
  margin-bottom: 46px;
  line-height: 1;
  font-size: 24px;
  color: #000;
}
.introText {
  margin-bottom: 16px;
  line-height: 1.35;
  font-size: 32px;
}
.introText b,
.introText u {
  font-weight: 800;
}
.linkItems {
  max-width: 600px;
}
.linkItem {
  margin-bottom: 20px;
}
.link {
  display: block;
  padding: 22px 104px 20px 24px;
  border-radius: 5px;
  box-shadow: 0 5px 14px 2px rgba(0,0,0,0.09);
  line-height: 1.5;
  font-weight: 700;
  background: white url(../images/report-nav-next.svg) center right 2.4rem no-repeat;
  background-size: 3rem;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 2px 7px 1px rgba(0,0,0,0.09);
  }
}
