.container {
  
  margin: 1rem 0 4rem 0;
  padding-bottom: 4rem;
  border-bottom: 1px #ccd3d7 solid;
 
  
}
.container a {
  text-decoration: underline;
}
.flex{
  display: flex;
  margin: 0 -2rem;

}
.flex div{
  margin: 0 2rem;

}
.flex .content{
  flex: 0 1 75%;
}
.flex .sidebar{
  flex: 0 1 25%;
}
.sectionTitle {
  margin-bottom: 0.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}
.container h2 {
  margin-bottom: 1.2rem;
  line-height: 1.2;
  font-size: 4rem;
  font-weight: 900;
}

.container h3 {
  margin-top: 3.8rem;
  margin-bottom: 0.8rem;
  line-height: 1.2;
  font-size: 3rem;
  font-weight: 800;
}



.container .sidebar h3 {
  margin-top: 0;
  font-size: 2.4rem;
  font-weight: 600;
}
.container p {
  line-height: 2.4rem;
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
}
.container ul {
  
  margin-bottom: 1.2rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  list-style: disc;
  
  
}

.container ol {
  
  margin-bottom: 1.2rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  list-style:decimal;
  
  
}
.container li{
  margin-bottom: 1rem;
  list-style-position: inside;
}

.container b,
.container strong {
  font-weight: 700;
}

