
.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 16px;
}

.courses {
  position: relative;
  display: flex;
  position: relative;
  background: white;
}

.weekdays {
  display: none;
}

.day {
  position: relative;
  padding-right: 31px;
}
.day[data-count="0"] {
  min-height: 120px;
  white-space: nowrap;
}
.day[data-count="0"]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 76px;
  bottom: 0;
  width: 2px;
  background: #e8e8e8;
}
.day h3 {
  margin-left: 62px;
  margin-bottom: 27px;
  line-height: 50px;
  font-size: 18px;
  font-weight: 700;
}
.day[data-count="0"] h3 {
  margin-left: 0;
  font-weight: 600;
}
.day[data-count="0"] > p {
  margin-top: -36px;
  font-size: 16px;
  font-weight: 700;
  color: #ccd3d7;
}

@media screen and (max-width: 450px) {
  .weekdays {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    margin-bottom: 6px;
  }
  .weekdays button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 54px;
    border-radius: 28px;
    border: 1px #ccd3d7 solid;
    font-weight: 800;
  }
  .weekdays button[data-active="true"] {
    background: #002538;
    border-color: #002538;
    color: white;
  }
  .weekdays button:disabled {
    background: repeating-linear-gradient(135deg,#fff,#fff .3rem,#ccd3d7 .5rem,#ccd3d7 0);
    color: #c3ccd0;
    pointer-events: none;
  }

  .wrapper {
    overflow: hidden;
  }
  .courses {
    display: block;
  }
  .day {
    display: none;
    padding-right: 0;
  }
  .day[data-active="true"] {
    display: block;
  }
  .day h3 {
    margin-bottom: 22px;
    margin-left: 0;
    border-bottom: 1px #ccd3d7 solid;
  }
}
.header {

  flex: 0 0 100%;
  height: auto;
  padding-bottom: 6px;
  margin-bottom: 30px;
  text-align: center;

  border-bottom: 1px #ccd3d7 solid;
  h2 {
    margin-bottom: 15px;
    line-height: 38px;
    font-size: 28px;
    font-weight: 900;
    color: #1e1e1e;
  }

}

.overview {
  flex: 1;
  max-width: calc(100%);
  padding: 10px 0 0 0px;
}

.main{
  display: flex;
}

@media screen and (max-width: 450px) {
  .main {
    flex-direction: column;
    max-width: 100%;
    padding: 0 12px;
  }
  .sidebar {
    width: 100%;
    min-height: auto;
    padding-right: 0;
    padding-bottom: 0;
    border: 0;

  }
  .overview {
    max-width: 100%;
    padding-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .wrapper {
    position: relative;
    z-index: 1;
    margin-left: -56px;
    margin-right: -56px;
    background: white;
  }
  .cards {
    width: 367px;
  }
}

@media screen and (min-width: 1024px) {
  .wrapper {
    margin-left: -160px;
    margin-right: -160px;
  }
}

@media screen and (min-width: 1280px) {
  .wrapper {
    margin-left: -240px;
    margin-right: -240px;
  }
}
