:root {
  --header-height: 7.8rem;
  --wrapper-padding: 2.5rem;
  --section-margin: 12rem;
}
@media screen and (max-width: 767px) {
  :root {
    --header-height: 5.8rem;
    --wrapper-padding: 1.6rem;
    --section-margin: 7.2rem;
  }
}
// body {
//   padding-top: var(--header-height);
// }
#root {
  overflow-x: hidden;
}
.header {
  z-index: 11;
  top: 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  padding: 0 var(--wrapper-padding);
  font-weight: 400;
  background: white;
}
.header .logo {
  flex: 0 0 150px;
  &:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 24px;
    position: relative;
    top: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./back.svg");
  }
}
.header .buttons {
  display: flex;
  flex: 0 0 4.6rem;
}

.header .logo img {
  width: 10.8rem;
  height: 1.8rem;
}

.header .logo svg {
  width: 10.8rem;
  height: 5.8rem;
}

.header button.usermenu-toggle {
  width: 4.8rem;
  height: 4.8rem;
  margin-right: 0.1rem;
  text-indent: -1000vw;
  overflow: hidden;
}
.usermenu-toggle {
  position: relative;
  z-index: 50;
  background: url(./menu-open.svg) center center no-repeat;
  background-size: 3.2rem;
  margin-right: -1.1rem;
}

.menu-with-sub h3 {
  border-bottom: 1px #ccd3d7 solid;
}
.sitemenu a,
.usermenu a {
  color: #008766;
  text-decoration: none;
}
.sitemenu ul {
  margin: 1.4rem 0 2.4rem;
}
.sitemenu li {
  margin-bottom: 0.6rem;
}

@keyframes slideIn {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(-301px);
    opacity: 1;
  }
}

.usermenu {
  position: absolute;
  right: -301px;
  width: 300px;
  background-color: white;
  z-index: 1000;
  display: block !important;
  padding: 2.4rem 3rem;
  min-height: calc(100vh - var(--header-height));
  top: var(--header-height);
  box-shadow: rgba(0, 0, 0, 0.06) 0 0.9rem 1.4rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px #f1f1f1 solid;
  transition: all 0.3s ease-in-out;
  a {
    color: #002538;
    font-size: 2rem;
    font-weight: 300;
  }
}
.usermenu li:not(:last-child) {
  margin-bottom: 1rem;
}
.usermenu[data-open="true"] {
  right: 0;
}
@media screen and (max-width: 767px) {
  .menu-category {
    margin-bottom: 0.6rem;
  }
  .menu-category.is-open ul {
    display: block;
  }
  .menu-category h3 {
    position: relative;
  }
  .menu-category h3:after {
    content: "";
    position: absolute;
    top: 1.1rem;
    right: 0;
    display: block;
    width: 0.9rem;
    height: 1.5rem;
    background: url(./caret.svg) center center no-repeat;
    background-size: 0.9rem;
  }
  .menu-category.is-open h3:after {
    top: 1.2rem;
    right: 0.4rem;
    transform: rotate(90deg);
  }
}
