.membership {
  margin: 1rem 0 3.5rem 0;
  border-bottom: 1px solid #cecece;
  &[data-listing="false"] {
    display: grid;

    .membershipActions {
      border: 1px solid #ccd3d7;
      background-color: #e5e9eb;
      border-radius: 6px;
      margin-top: 10px;
      padding: 0 10px;
    }
    .membershipDetails {
      display: block;
      h1 {
        grid-row: 1 / 3;
        margin: 0 0 0 0 !important;
      }
      &:before {
        top: 11px;
      }
    }
  }
}

.membership:last-child {
  border-bottom: none;
}
.membershipDetails {
  display: flex;

  position: relative;
  flex-wrap: wrap;
}
.membershipDetailsInner {
  display: grid;
  width: 100%;
  flex: 1 0 100%;
  margin: 0 2rem;
}

.membershipDetails h2 {
  padding-left: 2.2rem;
}
.membershipDetails h1 {
  width: 100%;

  padding-left: 2rem;
  margin: -0.8rem 0 1rem 0rem !important;
}

.membershipDetails:before {
  content: " ";
  position: absolute;
  top: 4px;
  left: 0rem;
  display: block;
  background-color: grey;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}
.membershipDetails[data-status="active"]:before {
  background-color: green;
}
.membershipDetails[data-status="paused"]:before {
  background-color: yellow;
}
.membershipDetails[data-status="finished"]:before {
  background-color: red;
}
.membershipDetails h2,
.membershipDetails h2 {
  font-size: inherit;
}

.renewalLinks {
  margin-left: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  a {
    height: 4.8rem;
    width: 35rem;
    border-radius: 5px;
    background-color: #008766;
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: system-ui, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;

    transition: all 0.3s ease-in-out;
  }
}
.renewal {
  margin-top: 2rem;
  h2 {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.8rem;
  }
  l h4 {
    margin: 2.4rem 0;
    font-weight: 400;
  }
}

.membershipDetails h2 span {
  padding-left: 0.6rem;
}
.membershipDetails strong {
  font-weight: 600;
}
.membershipInfo {
  margin-bottom: 2rem;
  strong {
    font-weight: 600;
  }
}
.externalLink {
  padding-right: 2.4rem;
  background: url(./externalLink.svg) center right no-repeat;
  background-size: 1.7rem;
}
.membershipActions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-items: flex-start;
  margin-bottom: 2rem;
  margin-top: 1rem;
  margin-left: 2rem;
  padding: 2px 0;
}

.membershipActions a {
  background: url(./externalLink.svg) center left 0.2rem no-repeat;
  background-size: 1.2rem;
  display: block;
  padding: 0 3rem 0 2rem;
  line-height: 3.6rem;
  font-weight: 600;
  text-decoration: underline;
  flex: 0 0 auto;
}
.membershipActions button {
  padding: 0 1rem 0 3rem;
  line-height: 3.6rem;
  flex: 0 0 auto;
  border-radius: 20px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
}
.membershipActions button[data-action="pause"] {
  background: url(./pause-icon.svg) center left 1rem no-repeat;
  background-size: 0.8rem;
}
.membershipActions button[data-action="undo_cancel_at_earliest_opportunity"],
.membershipActions button[data-action="restart"] {
  background: url(./restart-icon.svg) center left 1rem no-repeat;
  background-size: 1.2rem;
}
.membershipActions button[data-action="cancel_at_earliest_opportunity"],
.membershipActions button[data-action="cancel"] {
  background: url(./cancel-icon.svg) center left 1rem no-repeat;
  background-size: 1.2rem;
}
.membershipActions .readmore {
  position: relative;
  padding-left: 1rem;
  margin-left: 0.2rem;
  font-size: 1.4rem;
  color: #1e1e1e;
  display: inline-block;
}
.membershipActions .readmore:before {
  content: "|";
  position: absolute;
  left: 0;
  top: -0.3rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #ccd3d7;
}
.lastReport {
  font-weight: 600;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
