.container {
  position: relative;
  padding: 1.6rem 1.2rem;
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  text-align: center;
  // background: url('res/images/goldMemberAdBackground.jpg') 50% no-repeat;
  background: url('../../../../res/images/goldMemberAdBackground.jpg') 50% no-repeat;
  background-size: cover;
  color: #fff;

  &:before {
    content: "";
    background: rgba(42,49,66,0.65);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.title {
  position: relative;
  margin-bottom: 1.6rem;
  font: 2.2rem/3rem Sanchez,Times,serif;
  text-shadow: 0 0 3px #333;
}

.text {
  position: relative;
  margin-bottom: 3.6rem;
  text-shadow: 0 0 3px #333;
}

.button {
  position: relative;
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.8rem;
  margin-bottom: 1.6rem;
  border-radius: 2.4rem;
  line-height: 4.8rem;
  background: #002538;
}
