.header {
  display: flex;
  height: 40px;
  align-items: center;
}
.header h1 {
  padding-right: 3rem;
  line-height: 2.6rem;
  font-size: 2.4rem;
  font-weight: 900;
}
.header h4 {
  flex: 1;
  font-weight: 600;
}
.header strong {
  padding-right: 0.5rem;
  font-weight: 900;
}
.header b {
  font-weight: 700;
}
.nav {
  display: flex;
}
.nav a {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 0.8rem;
  background: url(../images/report-nav-prev.svg) center center no-repeat;
  background-size: 3rem;
  text-indent: -1000vw;
  overflow: hidden;
}
.nav a:last-child {
  background-image: url(../images/report-nav-next.svg)
}
.nav a[tabindex="-1"] {
  opacity: 0.5;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .header h4 span {
    display: none;
  }
}

@media screen and (max-width: 539px) {
  .header h4 {
    display: none;
  }
}
