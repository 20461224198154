.form {
  padding: 1.6rem;
}

.title {
  margin-bottom: 2.4rem;
  font: 2.1rem/1.35 Times, serif;
  color: #000000;
}

.row {
  display: flex;
  margin-bottom: 1.2rem;
}

.field {
  width: 100%;
  margin-bottom: 1.6rem;

  &[data-size="1/3"] {
    width: 34%;
    margin-right: 1.6rem;
  }
}

.fieldLabel {
  display: block;
  margin-bottom: 0.8rem;
}

.fieldInput {
  width: 100%;
  height: 4.8rem;
  padding: 0 1.2rem;
  border: 1px #e5e9eb solid;
  border-radius: 0.6rem;

  &[data-type="number"] {
    text-align: right;
  }

  &[data-type="select"] {
    padding-right: 3.8rem;
  }

  &:focus {
    border-color: #002538;
    box-shadow: 0 0 0 0.3rem rgba(#002538, .3);
  }
}

.buttons {
  text-align: right;

  .buttonWrapper {

    @media (max-width: 480px) {
      display: inherit;
      position: inherit;
      left: inherit;

      margin: 10px 0;
    }
  }
}



.button,
.unitButton {
  height: 4.8rem;
  padding: 0 1.6rem;
  margin-left: 1.2rem;
  border-radius: 0.6rem;
  font-size: 1.6rem;
  text-align: center;
  background-color: #002538;
  color: white;

  &:focus {
    border-color: #002538;
    box-shadow: 0 0 0 0.3rem rgba(#002538, .3);
  }
}

.unitButton {
  display: inline-block;
}

.unitButtons {
  text-align: center;

  .unitButton {
    display: inline;
    width: auto;
    padding: 0 24px;
    margin: 0 1.6rem 1.6rem 0;
    background-color: #002538;
    color: white;
  }
}

.actionButtons {
  display: flex;
  padding-top: 1.6rem;
  border-top: 1px #e5e9eb solid;
  button[type="button"] {
    display: block;
    width: 12rem;
    max-width: 100%;
    margin: 0 48px 0 auto;
    border-radius: 24px;
    background: white;
    color: #002538;
  }
  button[type="submit"] {
    margin: 0;
  }
}