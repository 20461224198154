

.widgetWrapper{
  /*margin-left: 60px;*/
  max-width: 960px;
}

.main {
  position: relative;
  min-height: calc(100vh - 5.8rem - 25.2rem);
  font-weight: 400;
  background: white;
}
body[class*="orange-bg"] .main {
  background: #fffaf7;
}
.header {
  padding: 26px 0;
}
.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1023px) {
    align-items: self-start;
  }
}
.actionButtons {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.toolsButton {
  height: 40px;
  padding: 0 52px 0 18px;
  margin-left: 20px;
  border-radius: 24px;
  border: 1px #ccd3d7 solid;
  line-height: 38px;
  background: white url(./images/toolbox.png) right 19px center no-repeat;
  background-size: 24px;
  color: #002538;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #002538;
  }

  @media screen and (max-width: 1023px) {
    margin-left: 0px;
    margin-top: 16px;
  }
}


.username {
  padding-bottom: 1rem;
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: 700;
  border-bottom: 1px #ccd3d7 solid;
}

.report {
  padding: 0 0 0 0;
  color: #002538;
}
.report section {
  padding-bottom: 2rem;
  border-bottom: 1px #ccd3d7 solid;
}
.report section:last-child {
  border-bottom: 0;
}
.sectionTitle {
  margin-bottom: 1.1rem;
  font-size: 2.4rem;
  font-weight: 900;
  text-transform: uppercase;
}
.intro {
  margin-left: 60px;
  margin-top: 2rem;
  margin-bottom: 0.9rem;
  font-size: 2.5rem;
  font-weight: 700;

}
.content {
  line-height: 1.5;
  margin-top: 5rem;
}

.clock{
  margin: 10rem auto;
}

.button {
  display: block;
  width: 100%;
  padding: 1rem 1.6rem;
  margin: 0 auto 2.4rem auto;
  border-radius: 2rem;
  font-weight: 600;
  text-align: center;
  background: #2ea369;
  color: white;
  width: 30rem;

}
.button[data-loading="true"] {
  opacity: 0.8;
  pointer-events: none;
  background: #2ea369 url(./images/ajax-loader.gif) center right 1.2rem no-repeat;
  background-size: 2rem;
}

.buttonSection {
  display: block;
  height: 40px;
  padding: 0 41px 0 18px;
  margin-top: 24px;
  margin-bottom: 56px;
  border-radius: 25px;
  font-weight: 600;
  background: #2ea369 url(./images/right-arrow-circle.svg) center right 12px
    no-repeat;
  background-size: 20px;
  color: white;

}
.buttonSection:disabled{
  pointer-events: none;
  display: none

}

.buttonSection[data-loading="true"] {
  pointer-events: none;
  opacity: 0.8;
  background-image:url(./images/ajax-loader.gif);
}

.formWrapper {
  max-width: 600px;
}


.container {
  display: flex;
  margin: 1rem 0 4rem 0;
  justify-content: space-between;
  max-width: 960px;
}
.content,
.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content {
  
  flex: 1 1 550px;
  padding-right: 3.6rem;
}
.sectionTitle {
  margin-bottom: 0.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}
.title {
  margin-bottom: 1.2rem;
  line-height: 1.2;
  font-size: 4rem;
  font-weight: 900;
}
.description {
  line-height: 1.5;
}
.description b,
.description strong {
  font-weight: 700;
}
.description p:not(:last-child),
.description ul:not(:last-child) {
  margin-bottom: 1.6rem;
}
.downloadWrapper img{
  margin-right: 1rem;
  position: relative;
  display: inline-block;
  top: -2px;


}
.download {

  display: inline-block;
  text-decoration: underline;  
  margin: 1.5rem 0;

  font-weight: 800;

}


@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column-reverse;
  }
  .content,
  .downloadBox {
    width: 100%;
  }
}
