.myFoodPage {
    max-width: 1170px;
    padding: 3.6rem 0 4.8rem 0;
    margin: 0 16px;

    @media screen and (min-width: 768px) {
        margin: 0 24px;
    }

    @media screen and (min-width: 1210px) {
        margin: 0 auto;
    }

    h1 {
        margin-bottom: 3.6rem;
        font: 2.6rem/3.3rem Times, serif;
        text-align: center;
        color: #000000;

        @media screen and (min-width: 768px) {
            margin-bottom: 4.8rem;
        }
    }
}

.dataTables {
    padding-bottom: 1.2rem;

    .loading {
        height: 5.4rem;
        margin-bottom: 1.6rem;
        background: url('./loading.svg') center center no-repeat;
        background-size: 5.4rem 5.4rem;
    }

    .emptyResultset {
        padding: 1.6rem;
        margin-bottom: 1.6rem;
        border-top: 1px #ddd solid;
        border-bottom: 1px #ddd solid;
        text-align: center;

        i {
            position: relative;
            top: 0.1rem;
            margin-right: 0.4rem;
            font-size: 1.9rem;
            color: #002538;
        }
    }

    table {
        width: 100%;
        margin-bottom: 2.4rem;

        button {
            position: absolute;
            font-size: 2rem;
            color: #dc5551;
        }
    }

    tr {
        border-bottom: 1px #ddd solid;
        transition: opacity 0.3s ease;

        &:first-child {
            border-width: 2px;
            border-right-color: #e9e9e9;
        }

        &[data-deleting="true"] {
            opacity: 0.35;
            pointer-events: none;
        }
    }


    td, th {
        padding: 1.2rem 0.6rem;
    }

    th {
        font-size: 1.4rem;
        font-weight: 600;
        text-align: left;
        text-transform: uppercase;
        color: #000000;

        &.date {
            width: 8rem;
        }

        &.delete {
            width: 3.2rem;
        }
    }

    td {
        font-size: 1.5rem;
        line-height: 2rem;

        a {
            margin-left: 0.6rem;
            color: #002538;
        }

        & .icon-star-empty {
            color: #aaa;
            margin-right: 0.6rem;
            cursor: pointer;
        }

        & .icon-star {
            color: #fbc531;
            margin-right: 0.6rem;
            cursor: pointer;
        }
    }

    .addButton {
        display: block;
        width: 100%;
        max-width: 343px;
        margin: -1.2rem auto 2.4rem;
        border-radius: 0.6rem;
        line-height: 48px;
        text-align: center;
        background: #002538;
        color: white;

        i {
            position: relative;
            top: 0.1rem;
            margin-right: 0.4rem;
            font-size: 2rem;
        }
    }
}

.textContent {
    padding: 1.8rem 1.6rem 2.6rem 1.6rem;
    border-radius: 0.6rem;
    font-size: 1.6rem;
    line-height: 1.5;
    background: #f4f6f7;

    h2 {
        margin-bottom: 0.6rem;
        font: 2.1rem/2.9rem Times, serif;
        color: #002538;
    }

    p {
        margin-bottom: 2.4rem;
    }

    ol, ul {
        padding-left: 2.4rem;
        margin-bottom: 2.4rem;
    }

    ol li {
        list-style: decimal;
    }

    strong {
        font-weight: 600;
    }

    *:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
        padding: 2.8rem 2.4rem 3.6rem 2.4rem;
    }
}


@media screen and (min-width: 1024px) {
    .myFoodPage {
        padding: 5.4rem 0 6.2rem 0;
    }

    .container {
        display: flex;
    }

    .dataTables {
        width: calc(60% - 12px);
        padding-bottom: 0;
        margin-right: 24px;

        table {
            margin-bottom: 3.6rem;

            button {
                font-size: 21px;
            }
        }

        th {
            font-size: 15px;

            &.date {
                width: 120px;
            }

            &.delete {
                width: 33px;
            }
        }

        td {
            font-size: 16px;
        }

        .addButton {
            position: relative;
            top: -0.8rem;
            display: inline-block;
            width: auto;
            padding: 0 2.4rem;
            margin-bottom: 3.6rem;
        }
    }

    .textContent {
        width: calc(40% - 12px);
        margin-top: -74px;
    }
}

@media screen and (min-width: 1260px) {
    .dataTables {
        width: calc(60% - 18px);
        margin-right: 36px;
    }

    .textContent {
        width: calc(40% - 18px);
    }
}