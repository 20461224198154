@import '../../../assets/styles/_colors.scss';

.Navbar {
    font-weight: 600;
    flex: 4 1 500px;

    padding-top: 1rem;
    padding-bottom: 2.5rem;
    background: #FFFFFF;
    border-top: 1px solid #CCD3D7;
    position: fixed;
    z-index: 12;
    left: 0;
    bottom: 0;
    background-color: white;
    width: 100%;    
    @media screen and (min-width: 1024px) {
        margin: 0 0;
        margin-left: 16px;
        padding-bottom: 1rem;
        position: static;
        border-top: none;
        background-color: transparent;
        }    

    &__items {
        position: relative;
        z-index: 12;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin: 0 -1rem;
    }

    &__item {
        position: relative;
        z-index: 12;
        flex: 1 1 10rem;
        font-size: 1.6rem;
        text-transform: capitalize;
        color: $color-dark-blue;
        margin: 0 .25rem;
        text-align: center;


        
        a {
            position: relative;
            z-index: 12;
            padding: 12px 12px 4px;
            display: inline-block;
            @media screen and (min-width: 1024px) {
                padding: .75rem 1.5rem .25rem;
            }
            span{
                position: relative;
                z-index: 12;
                color: $color-roede-blue;
                display: none;
                vertical-align: top;
                @media screen and (min-width: 1024px) {
                    display: inline-block;
                    margin-top: .35rem;
                }
                    
            }
            &.active{
                @media screen and (min-width: 1024px) {
                    border-radius: 20px;
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);

                }    
            }
            &:before{
                content: '';
                display: inline-block;
                width: 40px;
                height: 30px;
                margin-right: 4px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (min-width: 1024px) {
                    width: 30px;
                    height: 25px;
    
                }
            }            
   


        }
        &--food {

            
            a:before {
                background-image: url('./food.svg');
            }
            a.active:before {
                background-image: url('./foodSelected.svg');
            }



        }
        &--exercise {
            a:before {
                background-image: url('./exercise.svg');
            }
            a.active:before {
                background-image: url('./exerciseSelected.svg');
            }

        }        

        &--coach {
            a:before {
                background-image: url('./course.svg');
            }
            a.active:before {
                background-image: url('./courseSelected.svg');
            }

        }        
        &--me {
            a:before {
                background-image: url('./me.svg');
            }
            a.active:before {
                background-image: url('./meSelected.svg');
            }

        }
        &--dashboard {
            a:before {
                background-image: url('./dashboard.svg');
            }
            a.active:before {
                background-image: url('./dashboardSelected.svg');
            }


        }        
    }
}
