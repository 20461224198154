.registerMeasurements {
    transition: opacity 0.3s ease;

    @media screen and (min-width: 1024px) {
        margin: 0 -12px;
    }

    .fields {
        padding: 0.4rem 0 1.2rem;
        margin: 0 -8px;

        @media screen and (min-width: 1024px) {
            float: left;
            width: 66.66%;
            padding-right: 8px;
            margin: 0 4px;
        }
    }

    .field {
        float: left;
        width: 33.33%;
        padding: 0 8px;
        margin-bottom: 1.6rem;

        @media screen and (min-width: 1024px) {
            width: 20%;
        }

        label {
            display: block;
            width: 10rem;
            margin-bottom: 0.6rem;
            font-size: 1.5rem;
            font-weight: 600;
        }
        input[type="text"] {
            width: 100%;
            height: 4.4rem;
            padding: 0.4rem 5rem 0.4rem 1.2rem;
            border: 1px #e5e9eb solid;
            border-radius: 0.4rem;
            text-align: right;
            color: #000000;

            &:focus {
                border-color: #002538;
                box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
            }
        }

        span {
            display: inline-block;
            width: 4rem;
            margin-left: -4rem;
            border-left: 1px #e9e9e9 solid;
            font-size: 1.5rem;
            text-align: center;
            color: #8b8b8b;
            pointer-events: none;
        }
    }

    .totalBox {
        overflow: hidden;
        padding: 1.2rem 1.6rem;
        margin-bottom: 2rem;
        border-radius: 0.6rem;
        background: #f4f6f7;

        @media screen and (min-width: 1024px) {
            position: relative;
            top: 2px;
            right: 12px;
            float: right;
            width: calc(33.33% - 24px);
        }

        h4 {
            float: left;
            clear: right;
            width: calc(100% - 14rem);
            line-height: 4.6rem;
        }

        div {
            float: right;
            width: 11rem;
            line-height: 4rem;
            text-align: right;
            color: #8b8b8b;
        }

        strong {
            font-size: 3.2rem;
            font-weight: 600;
            color: #000000;
        }
    }

    .weekRating {
        border-top: 1px #ddd solid;
        padding-top: 1.6rem;
        margin-bottom: 2.6rem;

        @media screen and (min-width: 1024px) {
            position: relative;
            left: 12px;
            float: left;
            clear: both;
            width: calc(66.66% - 24px);
            margin-bottom: 0;
        }

        h4 {
            margin-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: 600;
        }

        input {
            display: none;
        }

        label {
            display: block;
            margin-bottom: 0.8rem;
            color: #505050;
            cursor: pointer;

            &:hover i {
                color: #000000;
            }

            input:checked + i {
                color: #3fb867;
            }

            input:checked + i + span {
                color: #000000;
            }

            @media screen and (min-width: 1024px) {
                float: left;
                margin-right: 36px;
            }
        }

        i {
            position: relative;
            top: 0.2rem;
            margin-right: 0.4rem;
            font-size: 2.4rem;
            color: #e5e9eb;
        }
    }

    button {
        width: 100%;
        height: 4.8rem;
        padding: 0 1.8rem;
        border-radius: 0.6rem;
        line-height: 4.8rem;
        background: #002538;
        color: white;

        @media screen and (min-width: 1024px) {
            width: calc(33.33% - 24px);
            margin-right: 12px;
        }
    }

    &[data-processing="true"] {
        opacity: 0.5;
        pointer-events: none;
    }
}
