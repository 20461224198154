.content{
  font-size: 1.8rem;
}
.content ul {
    padding-left: 2rem;
    margin: 2rem 0;
}
.content ul li {
    list-style: disc;
    margin: 1.5rem 0;
}
.content strong,
.content b{
  font-weight: 800;
}