.reportIntroWrapper {
  background-color: #FFFAF7;
  img {
    display: block;
    float: right;
    max-width: 20rem;
    margin: 3rem 2rem 0 2rem;
    border-radius: 50%;
  }
}


.message {
  margin-top: 3rem;
  border-top: 1px #e9e9e9 solid;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  background: #ffe58f;
  color: black;
}
.message > div {
  padding: 8px var(--wrapper-padding);
  margin: 0 auto;
}
.message a {
  position: relative;
  padding: 0 22px 0 28px;
  background: url(../../../components/ui/Message/alert.svg) left center no-repeat;
  background-size: 20px;

}
.widgetWrapper{
  /*margin-left: 60px;*/
  max-width: 960px;
}


.reportIntro {
  max-width: 550px;
  padding: 60px 0 70px 0;

}
.reportIntro a {
  text-decoration: underline;
}
.reportIntro h5 {
  margin-bottom: 4px;
  line-height: 1.375;
  font-weight: 900;
}
.reportIntro h1 {
  margin-bottom: 16px;
  line-height: 1.343;
  font-size: 32px;
  font-weight: 900;
  text-transform: uppercase;
}
.reportIntro p {
  line-height: 1.5;
  font-weight: 600;
}

.main {
  position: relative;
  min-height: calc(100vh - 5.8rem - 25.2rem);
  font-weight: 400;
  background: white;
}
body[class*="orange-bg"] .main {
  background: #fffaf7;
}
.header {
  padding: 26px 0;
}
.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1023px) {
    align-items: self-start;
  }
}
.actionButtons {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.toolsButton {
  height: 40px;
  padding: 0 52px 0 18px;
  margin-left: 20px;
  border-radius: 24px;
  border: 1px #ccd3d7 solid;
  line-height: 38px;
  background: white url(../images/toolbox.png) right 19px center no-repeat;
  background-size: 24px;
  color: #002538;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #002538;
  }

  @media screen and (max-width: 1023px) {
    margin-left: 0px;
    margin-top: 16px;
  }
}


.username {
  padding-bottom: 1rem;
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: 700;
  border-bottom: 1px #ccd3d7 solid;
}

.report {
  padding: 0 0 0 0;
  color: #002538;
}
.report section {
  padding-bottom: 2rem;
  border-bottom: 1px #ccd3d7 solid;
}
.report section:last-child {
  border-bottom: 0;
}
.sectionTitle {
  margin-bottom: 1.1rem;
  font-size: 2.4rem;
  font-weight: 900;
  text-transform: uppercase;
}
.intro {
  margin-left: 60px;
  margin-top: 2rem;
  margin-bottom: 0.9rem;
  font-size: 2.5rem;
  font-weight: 700;

}
.content {
  line-height: 1.5;
  margin-top: 5rem;
}

.clock{
  margin: 10rem auto;
}

.button {
  display: block;
  width: 100%;
  padding: 1rem 1.6rem;
  margin: 0 auto 2.4rem auto;
  border-radius: 2rem;
  font-weight: 600;
  text-align: center;
  background: #2ea369;
  color: white;
  width: 30rem;

}
.button[data-loading="true"] {
  opacity: 0.8;
  pointer-events: none;
  background: #2ea369 url(../images/ajax-loader.gif) center right 1.2rem no-repeat;
  background-size: 2rem;
}

.buttonSection {
  display: block;
  height: 40px;
  padding: 0 41px 0 18px;
  margin-top: 24px;
  margin-bottom: 56px;
  border-radius: 25px;
  font-weight: 600;
  background: #2ea369 url(../images/right-arrow-circle.svg) center right 12px
    no-repeat;
  background-size: 20px;
  color: white;

}
.buttonSection:disabled{
  pointer-events: none;
  display: none

}

.buttonSection[data-loading="true"] {
  pointer-events: none;
  opacity: 0.8;
  background-image:url(../images/ajax-loader.gif);
}

.formWrapper {
  max-width: 600px;
}

.error {
  padding: 24px 0 60px 0;
}
.error h1 {
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 900;
}
.error code {
  display: block;
  padding: 24px 24px;
  margin-bottom: 32px;
  border-left: 4px #d63031 solid;
  font-family: monospace;
  white-space: pre;
  background: #ffeaa7;
}
.error p, .error ul {
  margin-bottom: 24px;
}
.error ul {
  padding-left: 20px;
}
.error li {
  list-style: disc;
  margin-bottom: 6px;
}
.error li strong {
  display: block;
  font-weight: 900;
}