.container {
  min-height: 38.4rem;
  padding: 2.8rem 1.6rem;
}

.content {
  margin-bottom: 3.2rem;
  white-space: pre-wrap;
}

.exported {
  margin: -1.6rem 0 2.4rem;
  font-size: 1.4rem;
  color: #666;
}

.button {
  display: block;
  padding-right: 2.8rem;
  margin-bottom: 2.4rem;
  font-weight: 600;
  background-position: top 0.1rem right;
  background-repeat: no-repeat;
  background-size: 1.6rem;

  &[data-exporting="true"] {
    background-image: url(./ajax-loader.gif);
  }
}

.buttonDownload {
  background-image: url(./downloadIcon.png);
}

.buttonDelete {
  background-image: url(./deleteIcon.png);
}

.deleteContainer {
  padding: 16.4rem 3.2rem 0 3.2rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background: white url(./deleteImage.png) center top 2.4rem no-repeat;
  background-size: 12rem;
  transition: transform 0.3s ease;

  &[data-status="deleted"] {
    background-image: url(./deletedImage.png);
  }
}

.deleteContent {
  margin-bottom: 2.4rem;
  font-weight: 600;
  white-space: pre-wrap;
}

.deleteButtons {
  display: flex;
  justify-content: center;
  line-height: 3.2rem;
}

.deleteButton {
  font-weight: 600;
  text-decoration: underline;
}

.deleteButtonsSeparator {
  padding: 0 2.4rem;
  font-size: 2.4rem;
  font-weight: 300;
  color: #ccc;
}

.deleting {
  height: 2.4rem;
  background: url(./ajax-loader.gif) center center no-repeat;
  background-size: 2rem;
}
