.summaryBoxes {
    padding: 0.4rem 0;

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        margin: 0 -12px;
    }
}

.summaryBox {
    overflow: hidden;
    padding: 2.4rem 1.6rem 0.8rem 1.6rem;
    margin-bottom: 2.4rem;
    border-radius: 0.6rem;
    border: 1px #e5e9eb solid;
    background: #f4f6f7;

    @media screen and (min-width: 1024px) {
        width: 50%;
        margin-left: 12px;
        margin-right: 12px;
    }

    dt,
    dd {
        margin-bottom: 1.6rem;
    }

    dt {
        float: left;
        clear: right;
        width: calc(100% - 13.4rem);
    }

    dd {
        float: right;
        width: 11rem;
        line-height: 4rem;
        text-align: right;
        color: #8b8b8b;
    }

    strong {
        font-size: 3.2rem;
        font-weight: 600;
        color: #000000;
    }
}

.goldMemberAd {
    position: relative;
    text-align: center;
    background: url('./bg-gold-member-ad.jpg') center center no-repeat;
    background-size: cover;
    color: white;

    & > * {
        position: relative;
    }

    &:before {
        content: '';
        background: rgba(#2a3142, 0.65);
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    h3 {
        font: 2.2rem/3rem Times, serif;
        margin-bottom: 1.6rem;
    }

    p {
        margin-bottom: 3.6rem;
    }

    a {
        display: block;
        width: 100%;
        height: 4.8rem;
        padding: 0 1.8rem;
        margin-bottom: 1.6rem;
        border-radius: 0.6rem;
        line-height: 4.8rem;
        background: #002538;
    }

    span {

    }
}
