.myFoodPage {

    .SystemMessageRecipe {
      margin-bottom: 4.4rem;

      .systemMessages {
        margin: 0;
      }

      @media screen and (max-width: 1024px) {}

      @media screen and (max-width: 768px) {}

      @media (max-width: 480px) {}

    }
}

.customRecipeForm {
    position: relative;
    padding-top: 1.2rem;

    &[data-processing="true"] {
        opacity: 0.65;
        pointer-events: none;
    }

    section {
        margin-bottom: 3.6rem;
    }

    h3 {
        padding-bottom: 1.2rem;
        margin-bottom: 2.4rem;
        border-bottom: 2px #002538 solid;
        font: 2.1rem/2.6rem Times, serif;
        color: #000000;
    }

    input[type="text"],
    input[name="portions"],
    textarea {
        width: 100%;
        padding: 1.2rem;
        border: 1px #e5e9eb solid;
        border-radius: 0.6rem;
        color: #000000;

        &:focus {
            border-color: #002538;
            box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
        }
    }

    input[type="text"],
    input[name="portions"] {
        height: 4.8rem;
    }

    input[name="portions"] {
        width: 8rem;
        text-align: right;
    }

    input[type="checkbox"] {
        position: relative;
        top: 0.2rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.4rem;
        appearance: checkbox;
    }

    .field {
        margin-bottom: 1.6rem;

        label {
            display: block;
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.8rem;
        }

        &-number {
            label {
                float: left;
                padding-right: 1.2rem;
                line-height: 4.8rem;
            }
        }

        &-checkbox {
            label {
                display: block;
                font-size: 1.6rem;
                font-weight: 400;
            }
        }

        &-desc {
            padding-left: 2.4rem;
            margin: -0.4rem 0 0.4rem;
            font-size: 1.3rem;
            color: #8b8b8b;
        }

        &-picture {
            height: 20rem;
            margin-bottom: 1.6rem;
            border-radius: 0.6rem;
            overflow: hidden;
            display: none;

            &[data-visible="true"] {
                display: block;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .buttons {
        padding-top: 3.6rem;
        border-top: 1px #e5e9eb solid;
        text-align: right;

        button,
        a {
            display: inline;
            height: 4.8rem;
            padding: 0 2rem;
            border-radius: 0.6rem;

            &[type="submit"] {
                background: #002538;
                color: white;
            }
        }

        a {
            margin-right: 1.6rem;
            color: #002538;
        }
    }

    .icon-gr-heart-friendly-food {
        position: relative;
        top: 0.1rem;
        font-size: 2rem;
        color: #d84647;
    }

    .ingredientsInfo {
        overflow: hidden;
        margin-bottom: 1rem;
        font-size: 1.3rem;

        span {
            display: block;
            float: left;
            width: 50%;

            &:last-child {
                text-align: right;
            }
        }

        em {
            color: #000000;
        }

        strong {
            font-weight: 600;
        }
    }

    .ingredientsList {

        li {
            position: relative;
            padding: 1rem 0;
            border-top: 1px #ddd solid;
            font-size: 1.4rem;
            transition: opacity 0.3s ease;

            &:last-child {
                border-bottom: 1px #ddd solid;
            }
        }

        .ingredient {
            padding-right: 17rem;
            line-height: 1.8rem;

            span,
            a {
                display: block;
                color: #002538;
            }

            a {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #8b8b8b;
                pointer-events: none;
            }

            @media screen and (min-width: 1024px) {
                padding-right: 18rem;
                font-size: 15px;
                line-height: 3.6rem;

                span,
                a {
                    display: inline;
                    margin-left: 10px;
                }

                span:after {
                    content: ":";
                }
            }
        }

        .nutritionInfo {
            overflow: hidden;
            position: absolute;
            top: 1rem;
            right: 3rem;
            padding: 0 1.2rem;
            border-radius: 0.3rem;
            line-height: 3.6rem;
            background: #f4f6f7;

            span,
            strong {
                display: block;
                float: left;
                text-align: right;
            }

            span {
                width: 7rem;
                font-size: 1.3rem;
                text-align: left;
            }

            strong {
                width: 3.6rem;
                font-size: 1.6rem;
                line-height: 3.6rem;
                font-weight: 600;
                color: #000000;
            }
        }

        button {
            position: absolute;
            top: 1rem;
            right: 0;
            font-size: 2.2rem;
            line-height: 3.6rem;
            color: #DC5551;
        }
    }

    @media screen and (min-width: 768px) {
        section {
            width: calc(50% - 12px);
        }

        section:nth-child(3) {
            position: absolute;
            top: 1.2rem;
            right: 0;
        }
    }

    @media screen and (min-width: 1280px) {
        section {
            width: calc(50% - 24px);
        }
    }
}
