.memberships{

    h1{
        font-weight: 800;
        font-size: 2.5rem;
        margin: 2.5rem 0 4rem 0;
    }
}

.back {
    
    font-size: 1.8rem;
    font-weight: 600;
    margin: 1rem 0 2rem 0;
    position: relative;
    display: block;
    span{
        margin-left: .5rem
    }
    
    &:before{
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      position: relative;

      top: 3px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../components/layout/Header/back.svg');
  }    
}