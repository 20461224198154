.invoice {
  margin: 1rem 0 6rem 0;
  border-bottom: 1px solid #cecece; }

.invoice th,
.invoice td {
  text-align: left; }

.invoice:last-child {
  border-bottom: none; }

.invoiceDetails {
  display: flex;
  padding-left: 2.2rem;
  position: relative;
  justify-content: space-between;
  margin: 0 -1rem 2rem -1rem; }

.invoiceDetails:before {
  content: ' ';
  position: absolute;
  top: 4px;
  left: 1rem;
  display: block;
  background-color: grey;
  border-radius: 50%;
  width: 1rem;
  height: 1rem; }

.invoiceDetails[data-status="paid"]:before {
  background-color: green; }

.invoiceDetails[data-status="open"]:before {
  background-color: red; }

.invoiceDetails[data-status="uncollectible"]:before {
  background-color: red; }

.invoiceDetails h2,
.invoiceDetails p {
  margin: 0 1rem; }
  .invoiceDetails p:last-child{
    margin-right: 4rem;
  }
.invoiceDetails h2 {
  font-size: inherit; }

.invoiceDetails h2 span {
  padding-left: 0.6rem; }

.invoiceDetails strong {
  font-weight: 600; }

.invoiceUrl{
  margin-top: 1.5rem;
  text-decoration: underline;
  font-weight: 600;
  
}

.invoice {

  margin-bottom: 2em;
  padding-bottom: 1em;
}

.invoice table {
  width: 100%;
}

.invoice table tr th {
  font-weight: 600;
  border-top: 1px solid rgba(0,0,0, 0.3);
  border-bottom: 1px solid rgba(0,0,0, 0.3);
  padding: 10px 0;
}

.invoice table tr td {
  padding: 10px 0;
}

.invoice table tr td.center {
  text-align: center;
}

.invoice table tr td.left,
.invoice table tr th.left {
  text-align: left;
}

.invoice table td.status_item {
  color: #fff;
}


.invoice .show.invoice a {
  color: #1e1e1e;
  font-size: 1.6rem;
  font-weight: 500;
  transition: opacity 0.3s ease;
}

.invoice .show.invoice a:hover {
  opacity: 0.6;
}
