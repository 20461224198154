.ingredientSearch {
    margin-bottom: 1.6rem;

    &__input {
        width: 100%;
        height: 4.8rem;
        padding: 0 1.4rem;
        border-radius: 0.6rem;
        border: 1px #e5e9eb solid;
        font-size: 16px;
        background-color: white;

        &:focus {
            border-color: #002538;
            box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
        }
    }

    &__menu {
        position: absolute;
        z-index: 1;
        width: calc(100%);
        max-width: 480px;
        margin-top: 1rem;
        border-top: 1px #ddd solid;
        border-radius: 0.6rem;
        box-shadow: 0 2px 4px 0 rgba(80, 103, 150, 0.6);
        background-color: white;
        overflow: hidden;
    }

    &__item {
        position: relative;
        padding: 1.3rem 11rem 1.3rem 1.3rem;
        border-bottom: 1px #e5e9eb solid;
        font-size: 1.3rem;
        line-height: 1.4;
        cursor: pointer;
    }

    &__itemLabel {
        font-style: italic;
        color: #8b8b8b;

        &:not(:empty):before {
            content: " - ";
        }
    }

    &__itemKcal {
        position: absolute;
        top: 1.4rem;
        right: 1.3rem;
        width: 9rem;
        font-size: 1.2rem;
        text-align: right;
        white-space: nowrap;
        color: #000000;
    }

    &__addButton {
        position: relative;
        width: calc(100% + 2px);
        height: 4.8rem;
        margin: 0 -1px -1px -1px;
        line-height: 4.8rem;
        background: #002538;
        color: white;

        i {
            position: relative;
            top: 0.1rem;
            margin-right: 0.6rem;
            font-size: 2rem;
        }
    }

    &__loadingItems {
        height: 3.3rem;
        background: rgba(255, 255, 255, 0.65) url('./loading.svg') center center no-repeat;
        background-size: 3.6rem 3.6rem;
    }
}