.container {
  position: relative;
  padding: 2.4rem 5.4rem 2.4rem 1.2rem;
  border-bottom: 1px #e6e6e6 solid;

  &:last-child {
    border-bottom: 0;
  }
}

.face {
  position: absolute;
  top: 50%;
  right: 1.2rem;
  width: 3rem;
  height: 3rem;
  margin-top: -1.5rem;
}
