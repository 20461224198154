@import '../../assets/styles/_colors.scss';

.DietPage {
    position: relative;
}

[data-tooltip] {
    position: relative;
    cursor: help;
}

[data-tooltip]:before,
[data-tooltip]:after {
    content: "";
    transition: opacity 0.2s ease;
    opacity: 0;
}

[data-tooltip]:hover:before {
    content: "";
    position: absolute;
    left: 1rem;
    bottom: -6px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba($color-dark-blue, 0.95);
    opacity: 1;
}

[data-tooltip][data-tooltip-orientation="right"]:hover:before {
    left: auto;
    right: 1rem;
}

[data-tooltip]:hover:after {
    content: attr(data-tooltip);
    position: absolute;
    z-index: 1;
    bottom: -6px;
    left: 0;
    transform: translateY(100%);
    width: 20rem;
    padding: 1.4rem;
    border-radius: 3px;
    box-shadow: 1px 1px 2px 1px rgba(black, 0.3);
    font-size: 1.4rem;
    line-height: 1.35;
    text-align: left;
    text-transform: none;
    background-color: rgba($color-dark-blue, 0.95);
    color: white;
    opacity: 1;
}

[data-tooltip][data-tooltip-orientation="right"]:hover:after {
    left: auto;
    right: 0;
}

.group:after {
    content: "";
    display: table;
    clear: both;
}



.DietReport {
    margin-bottom: 2.4rem;

    &__title {
        margin-bottom: 3.6rem;
        text-align: center;
        font: 2.6rem/3.3rem Times, serif;
        color: $color-title-blue;
    }
}


.DietScore {
    max-width: 420px;
    margin-bottom: 1.6rem;
}

.DietScoreSection {
    position: relative;
    float: left;
    height: 12.4rem;
    padding: 1.6rem;
    border-radius: 0.6rem;
    background-color: $color-light-blue;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0.4rem;
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;
        background-color: $color-roede-blue-weak;
    }

    &--day {
        width: calc(63% - 0.8rem);
    }

    &--week {
        float: right;
        width: calc(37% - 0.8rem);

        .DietScoreItem {
            width: 50%;
        }
    }

    &__title {
        margin-bottom: 1.6rem;
        font: 1.8rem/1.5 Times, serif;
        text-align: center;
        text-transform: lowercase;
        color: $color-title-blue;
    }
}


.DietScoreItem {
    position: relative;
    float: left;
    width: 25%;
    text-align: center;

    &:first-child {
        span {
            display: block;
            width: 3.2rem;
            font-size: 1.2rem;
            line-height: 1.6rem;
            text-transform: lowercase;
        }
    }

    strong {
        display: block;
        width: 3.2rem;
        border-radius: 0.4rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        background-color: #999;
        color: white;

        &.success {
            background-color: #3fb575;
        }
    }

    i {
        font-size: 4.2rem;
        color: #99a8af;
    }

    span[data-badge] {
        position: relative;

        &:after {
            content: attr(data-badge);
            position: absolute;
            right: -0.4rem;
            bottom: 0;
            display: block;
            padding: 0 0.3rem;
            border-radius: 0.3rem;
            font-size: 1.2rem;
            line-height: 1.6rem;
            background-color: $color-roede-blue;
            color: white;
        }
    }

    .icon-gr-heart-friendly-food[data-active="true"] {
        color: #d84647;
    }

    .icon-gr-omega-3[data-active="true"],
    .icon-gr-nuts[data-active="true"] {
        color: #3fb575;
    }

    .icon-gr-comfort-food.active {
        color: #036675;
    }

    .icon-gr-comfort-food.over-limit {
        color: #f24e4e;
    }
}

.Score {

}


.DietReportSection {
    position: relative;
    padding: 2rem 1.6rem 1.6rem 1.6rem;
    margin-bottom: 1.6rem;
    border-radius: 0.6rem;
    background-color: $color-light-blue;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0.4rem;
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;
        background-color: $color-roede-blue-weak;
    }

    &__title {
        margin-bottom: 1.6rem;
        font: 1.8rem/1.5 Times, serif;
        color: $color-title-blue;
    }

    &__info[data-collapsed="true"] {
        display: none;
    }

    &__toggle {
        position: relative;
        height: 4.4rem;
        margin-bottom: 10px;
        text-align: center;

        button {
            position: relative;
            z-index: 1;
            height: 4.4rem;
            padding: 0 2.2rem;
            border-radius: 2.2rem;
            box-shadow: 0px 0px 0px 0.3rem white;
            background: #002538;
            color: white;
            cursor: pointer;
        }
    }
}

.DietReportItem {
    margin-bottom: 36px;

    &__title {
        float: left;
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        line-height: 2rem;
        text-transform: uppercase;

        i {
            color: #a4f48e;
        }
    }

    &__recommended {
        float: right;
        font-size: 1.2rem;
        line-height: 2rem;

        a {
            text-decoration: underline;
        }
    }
}



.MealRegistration {
    margin-bottom:4rem;

    .MealRegistrationHeader {
        position: relative;
        @media screen and (max-width: 768px) {
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    &__title {
        margin-bottom: 2.4rem;
        font: 2.4rem/3.6rem Times, serif;
    }

    .daily-menu {
        display: inline-block;
        width: 100%;
        max-width: 34.3rem;
        margin-bottom: 1.6rem;

        label {
            display: block;
            margin-bottom: 0.8rem;
            font-size: 1.5rem;
            text-align: left;
        }

        select {
            float: left;
            position: relative;
            display: block;
            width: calc(100% - 10rem);
            height: 4.8rem;
            padding: 0.6rem 3.2rem 0.6rem 1.2rem;
            border: 1px solid #e5e9eb;
            border-radius: 0.6rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            font-size: 1.6rem;
            color: #000000;
            background: white url('./arrow.svg') center right 1rem no-repeat;
            background-size: 1.2rem 1.2rem;
        }

        button {
            float: left;
            display: block;
            width: 10rem;
            height: 4.8rem;
            border-radius: 0.6rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            font-size: 1.6rem;
            text-align: center;
            background-color: $color-roede-blue;
            color: white;
        }
    }
}

.MealRegistrationFooter {
    padding: 2.4rem 0;
    text-align: center;

    button {
        display: inline-block;
        width: 100%;
        max-width: 34.3rem;
        margin: 0 auto 1.2rem auto;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        form {
            margin-right: 24px;
        }

        form + button {
            position: relative;
            top: 4.1rem;
        }

        button + button {
            margin-left: 24px;
        }
    }
}

.MealRegistrationItem {
    border: 1px solid $color-outlines;
    border-radius: 0.6rem;
    background-color: $color-light-blue;


    &__header {

    }
}

.MealEntries {
    background-color: white;
}

.MealEntry {
    position: relative;
    padding: 0.8rem 2rem;
    border-bottom: 1px $color-outlines solid;
    background-color: white;

    &__favorite {
        position: absolute;
        left: 0;
        top: 0.6rem;
    }

    &__delete {
        position: absolute;
        right: 0;
        top: 0.6rem;
        color: #f24e4e;
    }

    &__item {
        display: block;
        font-size: 1.3rem;
    }

    &__qty {
        display: block;
        font-size: 1.2rem;
        color: #777;
    }

    &__kcal {
        font-size: 1.4rem;
        font-weight: 600;
    }
}



.Button {
    height: 4.8rem;
    border-radius: 0.6rem;
    font-size: 1.6rem;
    line-height: 4.8rem;

    &__copy {
        border: 1px $color-roede-blue solid;
        background-color: white;
        color: $color-roede-blue;
    }

    &__save {
        background-color: $color-roede-green;
        color: white;
    }

    &__delete {
        background-color: #f24e4e;
        color: white;
    }

    i {
        position: relative;
        top: 0.1rem;
        margin-right: 0.8rem;
        font-size: 2rem;
    }
}


$border-width: 1px;

.meals {
    margin: 0 0 4rem 0;
    border-top: $border-width solid #e5e9eb;
    border-bottom: 1px solid #e5e9eb;
    background-color: #e5e9eb;
    /* background-color: #f4f6f7; */

    @media screen and (min-width: 768px) {
        margin: 0 -24px 4rem -24px;
    }

    @media screen and (min-width: 1210px) {
        margin: 0 auto 4rem auto;
        border: 1px solid #e5e9eb;
        border-radius: 6px;
    }

    &-item {
        padding: 1.4rem 16px 0 16px;

        & > h3 {
            position: relative;
            padding-bottom: 1.4rem;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 3.2rem;
            color: #000000;

            & > span {
                padding-left: 0.8rem;
                font-weight: 400;
                color: #505050;
            }

            button {
                position: absolute;
                top: -0.1rem;
                right: 0;
                width: 3.2rem;
                height: 3.2rem;
                border-radius: 50%;
                font-size: 2.4rem;
                font-weight: 400;
                line-height: 3.2rem;
                background: #e5e9eb;
                color: #002538;
            }
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    footer {
        position: relative;
        padding: 1.6rem 16px;
        margin: 0 -16px -1.6rem -16px;
    }

    &-actions {
        text-align: center;

        button {
            font-size: 1.3rem;
            text-transform: uppercase;
            color: #002538;

            @media screen and (min-width: 768px) {
                font-size: 1.4rem;
            }

            &[disabled] {
                color: #e5e9eb;
                cursor: not-allowed;
            }
        }

        span {
            position: relative;
            top: -1px;
            padding: 1.4rem;
            font-size: 1.4rem;
            color: #ddd;
        }
    }

    &-add,
    &-delete {
        display: none;
    }

    @media screen and (min-width: 768px) {
        .foodSearch {
            float: left;
            width: 45%;
            max-width: 36rem;
            margin-bottom: 1.6rem;
        }

        footer {
            float: right;
            margin-top: -76px;
            margin-right: 50px;
            padding: 1.4rem 16px 1.4rem 16px;
        }

        &-item-content {
            display: block;
        }
    }
}

.entries {
    padding: 0 16px;
    margin: 1.6rem -16px 0 -16px;
    border-top: 1px solid #ddd;
    background-color: white;
    clear: both;

    &:empty {
        border: none;
    }

    &-item {
        position: relative;
        padding: 1rem 0;
        border-bottom: 1px solid #ddd;
        transition: opacity 0.5s ease;

        &[data-deleting="true"] {
            opacity: 0.3;
            pointer-events: none;
        }

        &:last-child {
            border-bottom: 0;
        }

        .entry {
            padding: 0.4rem 13rem 0.4rem 2.4rem;
            font-size: 1.4rem;
            line-height: 1.5;

            @media screen and (min-width: 768px) {
                font-size: 1.4rem;
            }

            span {
                display: block;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                @media screen and (min-width: 768px) {
                    display: inline;
                    margin-right: 12px;
                }
            }

            & > span,
            & > em {
                cursor: pointer;

                @media (hover: hover) {
                    &:hover {
                        color: #000000;
                    }
                }
            }
        }

        strong {
            position: absolute;
            top: 1.4rem;
            right: 3rem;
            font-size: 1.6rem;
            font-weight: 600;
        }

        &-favorite {
            position: absolute;
            top: 1.4rem;
            font-size: 1.6rem;
            color: #8b8b8b;

            .icon-star {
              color: #fbc531;
            }
        }

        &-delete {
            position: absolute;
            top: 1.3rem;
            right: 0;
            font-size: 2rem;
            color: #dc5551;
        }

        &-icons {
            position: absolute;
            top: 1.5rem;
            right: 6.6rem;
            display: block;

            i {
                margin-left: 0.4rem;
            }

            .icon-spell-check {
                font-size: 1.5rem;
                color: #007079;
            }

            .icon-gr-quality-bad {
                color: #c10022;
            }

            .icon-gr-quality-neutral {
                color: #ff650d;
            }

            .icon-gr-quality-good {
                color: #1b8649;
            }

            .icon-gr-heart-friendly-food {
                color: #d84647;
            }
        }
    }
}

.diet-extras {
    padding: 3.2rem 1.6rem 1.6rem 1.6rem;
    margin: 0 -1.6rem;
    background: white;
}

.glasses-of-water {
    margin-bottom: 1.6rem;

    @media screen and (min-width: 1024px) {
        float: left;
        margin-right: 4.8rem;
    }

    input {
        display: none;
    }

    label {
        display: inline;
        padding-right: 0.6rem;
    }

    i {
        font-size: 2.4rem;
        cursor: pointer;
        color: #99a8af;

        @media (hover: hover) {
            &:hover {
                color: #8b8b8b;
            }
        }

        &[data-selected="true"] {
            color: #00b75d;
        }
    }
}

.pills-and-nuts {
    &__field {
        margin-bottom: 1.6rem;

        @media screen and (min-width: 768px) {
            float: left;
            margin-right: 4.8rem;
        }
    }

    label {
        cursor: pointer;
        margin-right: 0.8rem;
    }

    input {
        display: none;
    }

    &__icon {
        position: relative;
        top: 0.2rem;
        font-size: 2.4rem;
    }

    &__label:before {
        content: "\eba6";
        padding-right: 0.6rem;
        font-family: 'Simple-Line-Icons-Pro' !important;
        speak: none;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #e5e9eb;
    }

    input:checked + &__label:before {
        content: "\e9f8";
        color: #002538;
    }

    &__help {
        color: #002538;
    }
}

.DietPage__links {
  text-align: center;
}

.myFoodLink,
.aboutLink {
  display: inline-block;
  width: 100%;
  max-width: 34.3rem;
  border: 1px #e5e9eb solid;
  border-radius: 0.6rem;
  line-height: 4.6rem;
  font-size: 1.6rem;
  color: #002538;

  i {
    margin-right: 0.6rem;
    font-size: 2rem;
  }

  @media screen and (min-width: 768px) {
    display: inline-block;
  }
}

.myFoodLink {
  margin-bottom: 1.6rem;

  @media screen and (min-width: 768px) {
    margin-right: 24px;
  }

  i {
    color: #3fb867;
  }
}

#_hj_feedback_container div {
  z-index: 49 !important;
}
