.container {
  padding: 28px 16px;
  line-height: 1.35;
}
.content {
  margin-bottom: 32px;
}
.content p {
  margin-bottom: 20px;
}
.content strong {
  font-weight: 600;
}
.buttons {
  display: flex;
}
.buttons button {
  margin: 0 8px;
}
.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 44px;
  padding: 0 22px;
  margin: 0 auto;
  border-radius: 22px;
  border: 1px black solid;
  line-height: 42px;
  font-weight: 700;
}
.button i {
  margin-left: 12px;
  font-size: 20px;
}
.buttonYes {
  background: #2ea369;
  border-color: #2ea369;
  color: white;
}
.history {
  padding-top: 32px;
}
.history h3 {
  font-weight: 600;
  margin-bottom: -12px;
}
.confirm {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 16px;
  text-align: center;
  background: white;
  transition: transform 0.3s ease;
}
.loading {
  width: 24px;
  height: 24px;
  background: url(./ajax-loader.gif) center center no-repeat;
  background-size: 20px;
}
