.DateNavigation.shared {
    max-width: 1170px;
    margin-top: 1.4rem;
    margin-left: 16px;
    margin-right: 16px;

    @media screen and (min-width: 768px) {
        margin-top: 1.4rem;
        margin-left: 24px;
        margin-right: 24px;
    }

    @media screen and (min-width: 1210px) {
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 768px) {
    .DateNavigation__date {
        &[data-week="true"]:after {
            content: attr(data-extended);
            margin-left: 1rem;
            color: #8b8b8b;
        }

        &[data-week="false"]:before {
            content: attr(data-extended) " ";
        }
    }
}

.calendar--week {
    width: 34.2rem;
    margin-left: -17.1rem;
    border: 0;

    .calendar-weekdays li {
        width: 11.111%;

        &:first-child {
            font-weight: 600;
        }
    }

    .calendar-weeks {
        ul {
            overflow: hidden;
        }

        & > li {
            margin-bottom: -1px;
            cursor: pointer;

            &:hover li {
                background: #eee;
            }

            &.selected ul li {
                background: #eee;

                &:first-child {
                    width: 11.111%;
                    margin: 0;
                    background: #002538;
                    color: white;
                }
            }
        }

        & > li li {
            float: left;
            width: calc(11.111% + 1px);
            margin: 0 0 0 -1px;
            border: 1px solid #ddd;
            font-size: 1.6rem;
            line-height: 3.6rem;

            &:first-child {
                border: 0;
                font-weight: 600;
            }
        }

        .today {
            background-color: #e5e9eb;
        }

        .spacer {
            border-color: transparent;
            background: white !important;
        }

        .empty {
            border-color: transparent;
        }
    }
}