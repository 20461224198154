.button {
  display: block;
  width: 100%;
  padding: 1rem 1.6rem;
  margin-bottom: 2.4rem;
  border-radius: 2rem;
  font-weight: 600;
  text-align: center;
  background: #2ea369;
  color: white;
}
.button[data-loading="true"] {
  opacity: 0.8;
  pointer-events: none;
  background: #2ea369 url(../images/ajax-loader.gif) center right 1.2rem no-repeat;
  background-size: 2rem;
}

.clock{
  margin: 10rem auto;
  width: 200px;
  transform: scale(1.5);
}
.notOpenText{
  margin-top: 2rem;
  margin-bottom: 0.9rem;
  font-size: rem;
  font-weight: 700;
  font-size: 2.5em;
  text-align: center;
}
