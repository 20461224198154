.message {
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  background: #ffe58f;
  color: #000;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0 auto;
}
.message[data-type="success"] {
  background: #b7eb8f;
}
.content {
  margin: 0 38px;
}
.content strong{
  font-weight: 600;
}
.content a {
  position: relative;
  font-size: 1.4rem;
  padding: 0 22px 0 24px;
  text-decoration: underline;
  background: url(./alert.svg) 0 no-repeat;
  background-size: 18px;
  &:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 1023px) {
  .content {
    margin: 0 24px;
  }
}

@media screen and (max-width: 767px) {
  .media, .content {
    margin: 0 16px;
  }
}
