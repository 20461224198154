.button {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.2rem;
  background: #002538;
  color: white;
}
