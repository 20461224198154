.section {
  background: white;
}

.header {
  position: relative;
  padding: 1.4rem 16px;
  line-height: 3.2rem;
  background: #e5e9eb;
}

.actions {
  position: absolute;
  top: 1.4rem;
  right: 16px;
  width: 7rem;
  height: 3.2rem;
}

.title {
  font-size: 1.6rem;
  font-weight: 600;
  color: #000000;
}

.calories {
  padding-left: 1rem;

  &:before {
    content: '•';
    padding-right: 0.8rem;
    color: #e5e9eb;
  }
}

.unit {
  font-size: 1.4rem;
  font-weight: 400;
  color: #8b8b8b;
}

.registrations {
  padding: 0 16px;
  background: white;
  transition: opacity 0.5s ease;

  &[data-deleting="true"] {
      opacity: 0.3;
      pointer-events: none;
  }
}

.buttonContainer{
  padding: 2rem 0;
}

.copyButton {
  padding: 0 1.8rem;
  border: 1px #ccd3d7 solid;
  border-radius: 4rem;
  line-height: 3.8rem;
  font-size: 1.4rem;
  color: #000000;

  i {
    font-size: 1.6rem;
    margin-right: 0.4rem;
  }
}
