.card {
  position: relative;
  padding: 16px 88px 30px 62px;
  margin-bottom: 16px;
  border-bottom: 1px #ccd3d7 solid;
  transition: opacity 0.3s ease;

  &[data-loading="true"] {
    opacity: 0.65;
    pointer-events: none;
  }
}
.card img {
  position: absolute;
  left: 0;
  top: 12px;
  object-fit: cover;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f1f1f1;
}
.card h4 {
  font-size: 16px;
  font-weight: 600;
}
.card p {
  margin-top: 2px;
  font-size: 14px;
  color: #777;
}
.card button {
  position: absolute;
  right: 0;
  top: 2px;
  height: 40px;
  padding: 0 22px;
  margin-top: 14px;
  border-radius: 22px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  background: #036775;
  color: white;
}
.card button span {
  display: none;
}

@media screen and (min-width: 768px) {
  .card button span {
    display: inline-block;
  }
}
