.section {
  position: relative;
  font-weight: 400;
  background: white;
  color: #002538;
  overflow: hidden;
  border-radius: 1em;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.section[data-variant="bmi"] {
  box-shadow: none;
  border-radius: 0;
}
.section[data-variant="news"] {
  background: transparent;
  box-shadow: none;
  .media {
    width: 60%;
  }
  .media[data-cover] img {
    width: 60%;
    left: calc(40% + 38px);
  }
  .content {
    width: 40%;
    margin: 0 38px;
  }
}

.section[data-variant="dark"] {
  background: #c2d8dc;
  // color: white;
}

.section[data-variant="xmas"] {
  background: #f24e4e;
  color: white;
}

.section[data-variant="darkblue"] {
  background: #002538;
  color: white;
}

.section[data-variant="darkgreen"] {
  background: #008766;
  color: white;
}
.section[data-variant="nedivekt"] {
  background: #024c5c;
  color: white;
}

.section[data-variant="green"] {
  background: #def3e1;
}

.section[data-variant="orange"] {
  background: #fde6d2;
}

.section[data-variant="secondary"] {
  background: #fef7ee;
}

.section[data-variant="black"] {
  background: black;
  color: white;
  h3 {
    color: white;

    text-transform: none;
    font-weight: 400;
  }
}

.countdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 0;
  color: white;
}
.wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.wrapper[data-align] {
  display: flex;
  align-items: center;
}
.wrapper[data-align="left"] {
  flex-direction: row-reverse;
}
.media,
.content {
  width: 50%;
  margin: 0 38px;
}
// .media{
//   position: relative;
// }

.media[data-cover] > div {
  position: absolute;

  bottom: 0;
  left: calc(50% + 38px);
  right: 0;

  display: flex;
  font-size: 4rem;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media[data-cover] > span {
  position: absolute;
  display: block;
  border-radius: 20px;
  margin: 0 auto;
  width: 240px;
  left: 53%;
  top: 6%;

  text-transform: uppercase;
  padding: 1.5rem 3rem;
  text-align: left;
  font-size: 4.5rem;
  font-weight: 800;

  @media screen and (max-width: 767px) {
    left: 3%;
    top: 15%;
    font-size: 3rem;
    width: 200px;
  }
}

.media[data-cover] img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% + 38px);
  width: 50%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}
.wrapper[data-align="right"] .media[data-cover] img {
  left: auto;
  right: calc(50% + 38px);
}
.content {
  padding: 60px 0;
}

.content h1 p,
.content h1 {
  margin-bottom: 16px;
  line-height: 1.1;
  font-size: 64px;
  font-weight: 900;
}

.content h2 {
  margin-bottom: 16px;
  line-height: 1.1;
  font-size: 54px;
  font-weight: 900;
  em {
    font-style: italic;
  }
}

.content h2.slim {
  font-weight: 400;
}

.content h3 {
  margin-bottom: 0px;
  line-height: 1.1;
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}
.content strong {
  font-weight: 900;
}
.content p,
.content ul {
  margin-top: 16px;
  line-height: 1.35;
  font-size: 18px;
}
.content p a,
.content ul a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.content ul {
  list-style: disc;
  padding-left: 22px;
}
.content .button {
  margin-top: 36px;
}
.content [data-button] {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 100%;
  padding: 15px 36px;
  margin-top: 48px;
  border-radius: 24px;
  line-height: normal;
  font-size: 16px;
  font-weight: 600;
  background: #036775;
  color: white;
  span {
    line-height: normal;
  }
  small {
    line-height: normal;
    font-size: 0.7em;
  }
  &:hover {
    background: #2ea369;
  }
}

.content [data-button="db"] {
  background: #ca0005;
  //background: linear-gradient(101deg,#e84d50,#f28c48);
  &:hover {
    //background: #F5B5CF;
    background: #2ea369;
  }
}
.content [data-button="secondary"] {
  background: #f5b5cf;
  color: #312537;
  //background: linear-gradient(101deg,#e84d50,#f28c48);
  &:hover {
    //background: #F5B5CF;
    color: white;
    background: #2ea369;
  }
}

@media screen and (max-width: 1023px) {
  .media,
  .content {
    margin: 0 24px;
  }
  .wrapper[data-align="left"] .content {
    margin-right: 16px;
  }
  .wrapper[data-align="left"] .media {
    margin-left: 16px;
  }
  .media[data-cover] img {
    left: calc(50% + 16px);
  }
  .media[data-cover] div {
    left: calc(50% + 16px);
  }
  .wrapper[data-align="right"] .media[data-cover] img {
    right: calc(50% + 16px);
  }
  .content h2 {
    font-size: 44px;
  }
  .content h1 p,
  .content h1 {
    font-size: 48px;
  }
  .content p,
  .content ul {
    font-size: 16px;
  }
  .content [data-button] {
    margin-top: 36px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    border-radius: none;
  }
  .wrapper[data-align] {
    display: block;
  }
  .content {
    width: calc(100% - 32px) !important;
    margin: 0 16px !important;
  }

  .media {
    width: 100% !important;
    // margin: 0 16px !important;
  }
  .wrapper[data-align="left"] .media,
  .wrapper[data-align="right"] .media {
    margin: 0;
  }
  .media[data-cover] img {
    position: initial;
    width: 100% !important;
  }
  .media[data-cover] {
    position: relative;
  }
  .media[data-cover] div {
    left: 0;
    width: 100%;
    font-size: 13vw;
    overflow: hidden;
  }
  .wrapper[data-align="left"] .content {
    margin-left: 0;
  }
  .wrapper[data-align="right"] .content {
    margin-right: 0;
  }
  .content {
    padding: 36px 16px 48px 16px;
  }
  .content h2 {
    font-size: 40px;
  }
  .content h1 p,
  .content h1 {
    font-size: 43px;
  }
}
