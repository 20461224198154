.aboutButton {
    display: block;
    width: 100%;
    max-width: 34.4rem;
    margin: 0 auto 4rem auto;
    border: 1px solid #e5e9eb;
    border-radius: 0.6rem;
    line-height: 4.6rem;
    text-align: center;
    background-color: white;
    color: #002538;

    @media screen and (min-width: 768px) {
        margin-bottom: 5.4rem;
    }
}