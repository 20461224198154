.widget-wrapper {
  margin-top: 3.2rem;
}
.widget-row {
  display: flex;
  margin-bottom: 2.4rem;
}
.widget-row[data-full='true'] {
  display: block;
}
.widget-row .widget-section {
  width: 50%;
  padding: 3rem 2.4rem 2rem 2.4rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  background: #002538;
  color: white;
}
.widget-row .widget-section:nth-child(2n + 1) {
  margin-right: 1.2rem;
}
.widget-row .widget-section:nth-child(2n) {
  margin-left: 1.2rem;
}
.widget-row[data-full='true'] .widget-section {
  width: 100%;
  margin-right: 0;
}
.widget-row table {
  width: 100%;
  color: inherit;
  font-size: inherit;
  border-collapse: collapse;
}
.widget-row thead th {
  border-bottom: 1px #99a8af solid;
  font-weight: 700;
}
.widget-row thead th:first-child {
  text-align: left;
}
.widget-row tfoot td {
  border-top: 1px #99a8af solid;
  font-weight: 700;
}
.widget-row tbody tr:not(:last-child) td {
  border-bottom: 1px #ccd3d7 solid;
}
.widget-row th,
.widget-row td {
  padding: 1rem 0.4rem;
}
.widget-row th {
  padding-top: 0.4rem;
}
.widget-row th:not(:first-child),
.widget-row td:not(:first-child) {
  font-weight: 700;
  text-align: right;
}
.widget-row tbody td:first-child {
  font-weight: 300;
}
.widget-row tbody em {
  font-weight: 400;
  font-style: normal;
}
.widget-row a {
  display: inline-block;
  padding-right: 2rem;
  margin-top: 2.4rem;
  margin-left: 0.4rem;
  background: url('../images/arrow-right-light.svg') center right no-repeat;
  background-size: 1.2rem;
}
.widget-dates {
  margin-top: -0.4rem;
  font-size: 1.4rem;
}
.widget-dates em {
  font-style: italic;
}
.widget-dates strong {
  font-weight: bold;
}
.widget-food h4 {
  padding-left: 0.4rem;
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.widget-food h4 span {
  position: relative;
  top: -0.1rem;
  font-size: 1.4rem;
  font-weight: 300;
  text-transform: initial;
  letter-spacing: 0;
}
.widget-food[data-featured='false'] {
  background-color: #f1f1f1;
  color: #002538;
}
.widget-food li {
  position: relative;
  padding: 1rem 4.8rem 1rem 0.4rem;
  font-size: 1.5rem;
  font-weight: 300;
}
.widget-food li:not(:last-child) {
  border-bottom: 1px #385463 solid;
}
.widget-food[data-featured='false'] li:not(:last-child) {
  border-bottom: 1px #ccd3d7 solid;
}
.widget-food[data-featured='true'] tbody tr:not(:last-child) td {
  border-bottom: 1px #385463 solid;
}
.widget-food h5 {
  margin-bottom: 0.4rem;
  font-size: inherit;
  font-weight: 400;
}
.widget-row div[data-progressbar] {
  height: 0.8rem;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  border-radius: 0.4rem;
  background-color: #c3ccd0;
}
.widget-row div[data-progressbar] div {
  height: 100%;
  border-radius: 0.4rem;
}
.widget-row div[data-progressbar='good'] div {
  background: linear-gradient(to left, #9eef89 0%, #026675 100%);
}
.widget-row div[data-progressbar='bad'] div {
  background: linear-gradient(to left, #f24e4e 0%, #f89c46 100%);
}
.widget-food div[data-face] {
  position: absolute;
  top: 50%;
  right: 0.4rem;
  width: 3rem;
  height: 3rem;
  margin-top: -1.5rem;
}
.widget-food div[data-face='good'] {
  background: url('../images/face-good.png') center center no-repeat;
  background-size: contain;
}
.widget-food div[data-face='bad'] {
  background: url('../images/face-bad.png') center center no-repeat;
  background-size: contain;
}
.widget-food strong[data-recommended] {
  position: relative;
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
}
.widget-food strong[data-recommended]:after {
  content: attr(data-recommended);
  position: absolute;
  right: 0;
  font-weight: 300;
}
.widget-food div[data-images] {
  display: flex;
  justify-content: space-between;
  width: 19.4rem;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.widget-food div[data-images='heart'] {
  width: 3.6rem;
}
.widget-food div[data-images] img {
  width: 1.6rem;
}
.widget-food div[data-images='vitamin'] img {
  width: 2.4rem;
}
.widget-food strong span {
  font-weight: 300;
}
.widget-row .widget-activity {
  padding: 2.4rem;
}
.widget-activity h4 {
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: initial;
}
.widget-activity h4 span {
  position: relative;
  top: 0.1rem;
  font-size: 3.2rem;
  font-weight: 900;
}
.widget-weight h4 {
  padding-left: 0.4rem;
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.widget-weight li:not(:last-child) {
  border-bottom: 1px #385463 solid;
}
.widget-weight li {
  position: relative;
  padding: 1rem 7.8rem 1rem 0.4rem;
  font-size: 1.5rem;
  font-weight: 300;
}
.widget-weight li strong {
  position: absolute;
  right: 0.4rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .widget-row {
    display: block;
  }
  .widget-row .widget-section {
    width: 100%;
  }
  .widget-row .widget-section:first-child,
  .widget-row .widget-section:last-child {
    margin: 0 0 2.4rem 0;
  }
  .widget-row [data-desktop] {
    display: none;
  }
}
