.field-amount {
    overflow: hidden;
    margin-right: 0;

    label {
        float: left;
        display: block;
        width: 14rem;
        line-height: 4.2rem;
    }

    .amount {
        float: left;
        width: calc(100% - 21.4rem);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    span {
        display: block;
        float: left;
        width: 7.4rem;
        height: 4.2rem;
        padding: 0 0.6rem;
        border-radius: 0.6rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px #e5e9eb solid;
        background: #f6f6f6;
        font-size: 1.3rem;
        line-height: calc(4.2rem - 2px);
    }
}