.overlay {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
}

.modal {
  position: relative;
  z-index: 101;
  width: calc(100% - 3.2rem);
  max-width: 40rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 0.6rem;
}

.modalBody {
  max-height: 60rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &[data-isgold="true"] {
    height: calc(100vh - 20rem);
  }
}

.modalHeader {
  position: relative;
  padding: 1.6rem 1.2rem;
  border-bottom: 1px #e5e9eb solid;
  font-size: 1.8rem;
}

.modalTitle {
  font-weight: 600;
}

.modalClose {
  position: absolute;
  top: 1.4rem;
  right: 1.2rem;
  width: 3.2rem;
  height: 3.2rem;
  font-size: 2rem;
  line-height: 3.2rem;
  color: #3fb867;
}

.sectionTitle {
  padding: 1.2rem;
  font-size: 1.6rem;
  line-height: 1.15;
  text-transform: uppercase;
  background: #e5e9eb;
}
