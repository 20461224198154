.modal-overlay {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
}

.modal {
    z-index: 101;
    width: calc(100% - 3.2rem);
    max-width: 794px;
    border-radius: 0.6rem;
    box-shadow: 0 2px 6px 0 rgba(black, 0.3);
    background-color: white;

    &--small {
        max-width: 350px;
    }

    &-header {
        position: relative;
        padding: 1.6rem;
        font-size: 1.8rem;
        border-bottom: 1px solid #e5e9eb;
        color: #000000;

        button {
            position: absolute;
            top: 1.6rem;
            right: 1.6rem;
            font-size: 2rem;
            color: #3fb867;
        }
    }

    &-footer {
        height: inherit;
        padding: 1.6rem;
        border-top: 1px solid #e5e9eb;
        text-align: right;

        .buttonWrapper {
          margin-bottom: 10px;
        }

        button {
            height: 3.8rem;
            padding: 0 1.6rem;
            border-radius: 0.4rem;
            font-size: 1.4rem;
            line-height: 3.8rem;
            color: #002538;
        }

        button[type=submit] {
            margin-left: 1.2rem;
            background-color: #002538;
            color: white;
        }
    }

    &-body {
        padding: 2rem 1.6rem;
        max-height: calc(100vh - 17.2rem);
        font-size: 1.6rem;
        line-height: 1.35;
        overflow-y: auto;

        h2 {
            margin-bottom: 2.4rem;
            font: 1.9rem/2.45rem Times, serif;
            color: #000000;
        }

        .error {
            margin-bottom: 1.6rem;
            font-size: 1.4rem;
            color: #ff6e78;

            i {
                position: relative;
                top: 0.4rem;
                margin-right: 0.4rem;
                font-size: 2rem;
            }
        }

        .field {
            float: left;
            margin: 0 1.6rem 1.6rem 0;

            &:last-child {
                margin-right: 0;
            }
        }

        .field-1-3 {
            width: calc(25% - 0.8rem);
        }

        .field-2-3 {
            width: calc(75% - 0.8rem);
        }

        label {
            display: block;
            margin-bottom: 0.6rem;
        }

        .amount {
            text-align: right;
        }

        input, select {
            width: 100%;
            height: 4.2rem;
            padding: 0 1.2rem;
            border: 1px solid #b7b7b7;
            border-radius: 0.6rem;
            font-size: 16px;
            line-height: 4rem;
            background: white;
        }
    }
}
