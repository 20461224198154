.panel {
    position: relative;
    max-width: 1170px;
    padding: 0 16px;
    margin: 0 auto 2rem auto;

    @media screen and (min-width: 768px) {
        padding: 0 24px;
        margin-bottom: 3.6rem;
    }

    @media screen and (min-width: 1170px) {
        padding: 0;
    }

    &:last-child {
        margin-bottom: 4rem;
    }
}


.moreLink {
    padding-right: 2.5rem;
    margin-right: 2.4rem;
    line-height: 3.6rem;
    font-size: 1.8rem;
    font-weight: 900;
    background: url(./arrow-right-dark.svg) center right no-repeat;
    background-size: 1.8rem;
    color: #1e1e1e;
}

.panelHeader {
    position: relative;
    padding-bottom: 0.8rem;
    border-bottom: 2px #002538 solid;

    @media screen and (min-width: 1210px) {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}

.panelBody {
    padding: 2rem 0;
    transition: opacity 0.3s ease;

    @media screen and (min-width: 768px) {
        padding: 2.8rem 0;
    }

    &[data-processing="true"] {
        opacity: 0.65;
        pointer-events: none;
    }
}

.panelTitle {
    font: 2.4rem/3.6rem Times, serif;
    color: #000000;
}

.panelSubTitle {
    display: block;
    color: #8b8b8b;

    @media screen and (min-width: 768px) {
        display: inline-block;
        margin-left: 1.6rem;
    }
}

.panelInfoText {
    margin-top: -0.8rem;
    margin-bottom: -0.4rem;
    font-size: 1.4rem;
    line-height: 3.6rem;
    color: #8b8b8b;

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
    }
}

.panelReloadButton {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px #e5e9eb solid;
    border-radius: 0.6rem;
    padding: 0 1.2rem;
    font-size: 2rem;
    line-height: 3.4rem;
    color: #002538;
}


.panelBody,
.panelReloadButton {
    transition: opacity 0.3s ease;
}

.panel[data-loading=true] {
    .panelBody,
    .panelReloadButton {
        opacity: 0.5;
        pointer-events: none;
    }

    .panelLoading {
        overflow: hidden;
        position:absolute;
        bottom: -2px;
        width: 100%;
        height: 2px;

        div {
            position: absolute;
            left: -24px;
            background: radial-gradient(#e5e9eb, #002538);
            width: 24px;
            height: 2px;
        }

        div:nth-child(1) { animation: loading 1.2s infinite; }
        div:nth-child(2) { animation: loading 1.2s 0.3s infinite; }
        div:nth-child(3) { animation: loading 1.2s 0.6s infinite; }

        @keyframes "loading" {
            from { left: -24px; }
            to { left: 100%; }
        }
    }
}