.section[data-variant="gold"] {
  background: #024c5c;
  color: white;
  ul {
    li {
      color: white;
    }
    li:before {
      filter: invert(100%);
    }
  }
}
.section[data-variant="membership"] {
  background: #def3e1;
}
.section {
  overflow: hidden;
  .sectionHeadline {
    svg {
      max-width: 240px;
    }
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
  }
  h1 {
    font-size: 34px;
    color: #002538;
    font-weight: 600;
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  ul {
    margin-top: 1em;
    padding-left: 0;
    cursor: auto;
    list-style: none;
    position: relative;
    li {
      padding-left: 3rem;
      margin-bottom: 1.6rem;
      line-height: 1.375;
      color: #333;
      position: relative;
    }
    li:before {
      content: "";
      position: absolute;
      top: 0.6rem;
      left: 0;
      width: 1.4rem;
      height: 1.2rem;
      background: url(../../../pages/BuyMembershipPage/img/checkmark-dark.svg)
        no-repeat;
      background-size: contain;
    }
  }
  .readmore {
    margin-top: 1.2em;
    margin-left: 48px;
    a {
      text-decoration: underline;
      color: #008766;
    }
    a:hover {
      text-decoration: none;
    }
  }
  @media screen and (min-width: 768px) {
    .price {
      bottom: 35px;
    }
  }
  .signup {
    max-width: 1300px;
    margin: 4rem auto;
  }

  @media screen and (max-width: 767px) {
    .price {
      top: calc(50vw - 30px);
      scale: 0.5;
      right: -80px !important;
    }
  }
  .price {
    position: absolute;
    right: 0;

    height: 80px;
    width: 340px;

    padding: 25px 10px 5px 150px;
    border-radius: 25px 0 0 25px;

    font-size: 18px;
    color: #d60000;
    background-color: rgb(236, 236, 236);

    span {
      display: block;
      font-size: 16px;
    }
    img.app {
      position: absolute;
      width: 100px;
      height: 197px;
      left: 30px;
      top: -100px;
    }
  }
}
