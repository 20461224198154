.infoModal {

    &__title {
        font-size: 2rem;
        line-height: 2.6rem;
        text-align: center;
        color: #002538;
    }

    i { color: #007079; }

    table {
        width: 100%;
        margin-bottom: 1.6rem;
    }

    tr {
        border-bottom: 1px #e5e9eb solid;
    }

    th {
        padding: 1.2rem 0.4rem;
        border-bottom: 2px #e5e9eb solid;
        color: #000000;
    }

    td {
        padding: 1.2rem 0.4rem;
        font-size: 1.4rem;

        &:last-child {
            font-weight: 600;
            text-align: right;
            color: #000000;
        }
    }

    a {
        color: #3fb867;
    }
}