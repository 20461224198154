.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.mainButton {
  position: absolute;
  top: 1.1rem;
  right: -0.3rem;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.7rem;
  color: #002538;

  &[data-isopen="true"] {
    background: #e5e9eb;
  }
}

.actions {
  position: absolute;
  z-index: 11;
  top: 4.6rem;
  right: -0.7rem;
  padding: 0.4rem 0;
  border: 1px #e5e9eb solid;
  border-radius: 0.4rem;
  box-shadow: 0 3px 12px rgba(#2a3142, 0.15);
  white-space: nowrap;
  background: white;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: auto;
    display: inline-block;
  }

  &:before {
    right: 0.9rem;
    top: -16px;
    border: 8px solid transparent;
    border-bottom-color: rgba(#2a3142, 0.15);
  }

  &:after {
    right: 1rem;
    top: -14px;
    border: 7px solid transparent;
    border-bottom-color: white;
  }
}

.actionButton {
  display: block;
  width: 100%;
  padding: 0 1.6rem;
  line-height: 3.2rem;
  font-size: 1.4rem;
  text-align: left;

  &[data-disabled="true"] {
    opacity: 0.4;
    pointer-events: none;
  }

  &[data-type="delete"] {
    color: #dc5551;
  }

  @media (hover: hover) {
    &:hover{
      background: #002538;
      color: white;

      &[data-type="delete"] {
        background: #dc5551;
      }
    }
  }
}
