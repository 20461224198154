
.registerExercise {
    padding-top: 0.4rem;

    a {
        color: #002538;
    }

    select {
        width: 100%;
        height: 4.8rem;
        padding: 0.4rem 1.2rem;
        margin-bottom: 0.6rem;
        border: 1px #e5e9eb solid;
        border-radius: 0.4rem;
        background: white;
        color: #000000;
        background: url(./arrow.svg) center right 1.2rem no-repeat;
        background-size: 1.4rem 1.4rem;

        &:focus {
            border-color: #002538;
            box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
        }
    }

    .equivalence {
        margin: 2.4rem 0 0.8rem;
        font-size: 1.4rem;
        text-align: right;
        color: #8b8b8b;
    }
}

.exerciseList {
    margin-top: 1.2rem;
    margin-bottom: 0.6rem;

    li {
        position: relative;
        padding: 1rem 0;
        border-top: 1px #ddd solid;
        font-size: 1.4rem;
        transition: opacity 0.3s ease;

        &:last-child {
            border-bottom: 1px #ddd solid;
        }

        &[data-deleting="true"] {
            opacity: 0.35;
            pointer-events: none;
        }
    }

    .icon-gr-strength {
        position: absolute;
        top: 1rem;
        right: 13rem;
        font-size: 2.2rem;
        line-height: 3.6rem;
        color: #777f90;
    }

    .exercise {
        padding-right: 16rem;
        line-height: 1.8rem;

        span,
        a {
            display: block;
        }

        a {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        @media screen and (min-width: 768px) {
            padding-right: 18rem;
            font-size: 15px;
            line-height: 3.6rem;

            span,
            a {
                display: inline;
                margin-right: 10px;
            }

            span:after {
                content: ":";
            }
        }
    }

    .points {
        overflow: hidden;
        position: absolute;
        top: 1rem;
        right: 3rem;
        padding: 0 1.2rem;
        border-radius: 0.3rem;
        line-height: 3.6rem;
        background: #f4f6f7;

        span,
        strong {
            display: block;
            float: left;
            text-align: right;
        }

        span {
            width: 2rem;

        }

        strong {
            width: 4.6rem;
            font-size: 1.6rem;
            line-height: 3.6rem;
            font-weight: 600;
            color: #000000;
        }
    }

    button {
        position: absolute;
        top: 1rem;
        right: 0;
        font-size: 2.2rem;
        line-height: 3.6rem;
        color: #DC5551;
    }
}

@media screen and (min-width: 768px) {
    .exerciseList {
        li {
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
        }

        .icon-gr-strength,
        .points,
        button {
            top: 0.8rem;
        }
    }
}