.searchResults {
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 9.7rem; /* search input height */
  overflow-y: scroll;
}

.sectionTitle {
  padding: 1.2rem 1.6rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  background: #f4f6f7;
  color: #000000;

  @media screen and (min-width: 640px) {
    font-size: 1.6rem;
  }
}

.foodList {
  padding: 0 1.6rem;
}

.foodItem {
  position: relative;
  padding: 1.2rem 4rem 1.2rem 0;
  border-bottom: 1px #e5e9eb solid;
  font-size: 1.3rem;
  line-height: 1.5;

  @media screen and (min-width: 640px) {
    font-size: 1.4rem;
    padding-right: 4.4rem;
  }

  &:last-child {
    border: 0;
  }

  &[data-hasimage="true"] {
    padding-left: 5rem;

    @media screen and (min-width: 640px) {
      padding-left: 5.4rem;
    }
  }
}

.foodItemImage {
  position: absolute;
  left: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 0.3rem;

  @media screen and (min-width: 640px) {
    width: 4.3rem;
    height: 4.3rem;
  }
}

.foodItemName {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (min-width: 640px) {
    font-size: 1.5rem;
  }
}

.foodItemType {
  padding-right: 1.2rem;
  font-style: italic;
  color: #8b8b8b;
}

.foodItemButton {
  position: absolute;
  top: 1.8rem;
  right: 0;
  display: block;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 2.8rem;
  text-align: center;
  background: #e5e9eb;
  color: #002538;
  transition: all 0.3s ease;

  @media screen and (min-width: 640px) {
    top: 1.7rem;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    font-size: 2.1rem;
  }

  &:after {
    content: '+';
  }

  &[data-ishighlighted="true"],
  &:hover {
    background: #e5ecfd;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  85% {
    transform: translateX(1%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.section {
  animation: fadeIn 0.3s ease-in-out;
  animation-fill-mode: both;
}
.section:nth-child(1) { animation-delay: 0.1s; }
.section:nth-child(2) { animation-delay: 0.2s; }
.section:nth-child(3) { animation-delay: 0.3s; }