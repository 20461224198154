.nutritionBar {
    clear: both;
    position: relative;
    height: 2.4rem;
    padding: 2px;
    border-radius: 1.2rem;
    border: 1px #ececec solid;
    background: white;

    &__value {
        position: relative;
        border-radius: 1rem;
        background-color: #002538;
        height: 100%;
        transition: width 0.3s ease;
    }

    &__overlay {
        position: absolute;
        top: 2px;
        left: 2px;
        height: calc(2.4rem - 6px);
        border-radius: 1rem;
        background-image: linear-gradient(-45deg, #2a3142 25%, transparent 25%, transparent 50%, #2a3142 50%, #2a3142 75%, transparent 75%, transparent);
        background-size:4px 4px;
    }

    &__label {
        position: absolute;
        right: 0;
        top: 2.4rem;
        padding: 0.5rem;
        border-radius: 2px;
        font-size: 1.2rem;
        text-align: center;
        white-space: nowrap;
        transform: translate(50%);
        background-color: #e5e9eb;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: -6px;
            width: 0;
            height: 0;
            margin-left: -6px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #e5e9eb;
        }

        &--danger {
            background-color: #3fb867;
            color: white;

            &:after {
                border-bottom-color: #3fb867;
            }
        }

        &--success {
            background-color: #3fb575;
            color: white;

            &:after {
                border-bottom-color: #3fb575;
            }
        }
    }
}