.bookCarouselWrapper {
  font-weight: 400;

  color: #002538;
  margin-bottom: 60px;

  @media screen and (max-width: 767px) {
    border-radius: 0;
    box-shadow: none;
  }

  h2 {
    font-size: 44px;
    margin-left: 24px;
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }
  .bookCarousel {
    //relative w-full overflow-x-auto min-h-[256px] lg:min-h-[384px]
    position: relative;
    width: 100%;
    overflow-x: auto;
    min-height: 256px;

    .books {
      //mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16 lg:space-x-0
      display: inline-flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory;
      scroll-padding: 0 20px;
      gap: 20px;
      padding: 20px 0;
      .book {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 250px;
        height: 100%;
        padding: 0 20px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          overflow: hidden;
          border-radius: 20px;
        }
        h3 {
          font-size: 20px;
          font-weight: 900;
          margin-top: 10px;
        }
        p {
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
  }
}
