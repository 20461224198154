
.registration{
    max-width: 60.2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 16px;
}

.logoutButton{
    text-decoration: underline;
}

.registration{
  .goldTypeDesciption{
      display: block;
      width: calc(100% - 1.2rem);
      padding: 3rem;
      margin: 0 0.6rem 3.6rem 0.6rem;
      border-radius: 0.5rem;
      border: 1px #ccd3d7 solid;
      box-shadow: rgba(0, 0, 0, 0.06) 0 0.9rem 1.4rem;
      line-height: 2.4rem;
      font-size: 1.8rem;
      font-weight: 300;
      color: #002538;
      cursor: pointer;
      transition: border-color 0.3s ease;
      text-align: center;
      font-weight: 600;
      font-size: 2rem;

      &[data-option="offer"] {
        border: 1px #667c88 solid;
        background-color: #f9eb45;
      }

      &[data-option="restricted"] {
        border: 1px #667c88 solid;
        background-color: #f24e4e;
        color: white;
      }
  }
}

.registration *[data-hidden="true"]{
    transition: max-height 0.5s;
    transition: opacity 0.4s;
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 0;
}
.registration .registrationform[data-hidden="false"]{
    max-height: 100vh;
    opacity: 1;
}

.registration *[data-hidden="true"]{
    transition: max-height 1s;
    overflow: hidden;
    max-height: 0;
    opacity: 0.5;
    margin: 0 !important;
}
.registration .registrationform[data-hidden="false"]{
    max-height: 100vh;
}

.registration h2 {
    margin-bottom: 0.4rem;
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 900;
}
.registration .text {
    margin: 1rem 0;
    color: #1e1e1e !important;
    button {
        color: #2ea369;
    }
}

.registration strong{
    font-weight: 900;
}
.registrationform {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 3.6rem auto;
    max-width: 60.2rem;
    transition: all 0.3s ease-in-out;

}
.registrationform > div {
    width: 100%;
}
.registrationform hr {
    width: 100%;
    height: 1px;
    margin: 0.9rem 0 2.4rem;
    border: 0;
    background: #ccd3d7;
}
.registrationform label {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 1.5rem;
}
.registrationform input[type="password"],
.registrationform input[type="text"],
.registrationform input[type="email"],
.registrationform select {
    width: 100%;
    height: 4.8rem;
    padding: 0 2.3rem;
    border-radius: 2.4rem;
    border: 1px #002538 solid;
    font-weight: 600;
    background: white;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.registrationform label span {
    position: absolute;
    top: 1.3rem;
    left: 2.4rem;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    transition: all 0.3s ease-in-out;
}
.registrationform input[type="password"]:focus + span,
.registrationform input[type="text"]:focus + span,
.registrationform input[type="email"]:focus + span,
.registrationform select:focus + span,
.registrationform input:not([value=""]) + span,
.registrationform select:not([data-option=""]) + span {
    top: -0.8rem;
    left: 3rem;
    padding: 0 0.4rem;
    font-size: 1.2rem;
    font-weight: 400;
    background: white;
    color: #333;
}
.registrationform input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0.7rem 0 0 0.7rem;
}
.registrationform input[type="checkbox"] + p {
    position: relative;
    min-height: 2.4rem;
    padding: 0.8rem 0 0.6rem 3.8rem;
}
.registrationform input[type="checkbox"] + p:before,
.registrationform input[type="checkbox"]:checked + p:before {
    content: "";
    position: absolute;
    left: 0.7rem;
    top: 0.7rem;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.5rem;
    border: 1px #002538 solid;
}
.registrationform input[type="checkbox"]:checked + p:before {
    background: url(./checkmark-green.svg) center center no-repeat;
    background-size: 1.3rem;
}
.registrationform button,
.purchasesection button {
    height: 4.8rem;
    width: 100%;
    border-radius: 28px;
    background-color: #DEF4FF;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #124966;
    font-family: system-ui, 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    transition: all 0.3s ease-in-out;
    &[data-margin="true"] {
        margin-bottom: -32px;
    }
}
.registrationform button:disabled,
.purchasesection button:disabled {
    border: 1px #ccd3d7 solid;
    background: repeating-linear-gradient(
        135deg,
        #fff,
        #fff 0.3rem,
        #ccd3d7 0.5rem,
        #ccd3d7 0.5rem
    );
    color: #c3ccd0;
    cursor: not-allowed;
}
.registrationform input[name="postal_code"] + span:after {
    content: "nr";
}
.registrationform label[data-field="postal_code"] {
    width: 11.8rem;
}
.registrationform label[data-field="city"] {
    width: 20.7rem;
}

.registrationform .autofillFix {
    visibility: hidden;
}
.registrationform input:disabled,
.registrationform input:disabled ~ span{
    opacity: 0.4;

}
@media screen and (min-width: 768px) {
    .registrationform {
        margin: 3.6rem 0 0 0;
    }
    .registrationform input[name="postal_code"] + span:after {
        content: "nummer";
    }
    .registrationform label {
        width: 48.7%;
    }
    .registrationform label[data-field="address"] {
        width: 100%;
    }
    .registrationform label[data-field="postal_code"] {
        width: 15.2rem;
    }
    .registrationform label[data-field="city"] {
        width: 43.4rem;
    }
    .registrationform label[data-field="terms"] {
        width: 100%;
    }
}

.forgottenPasswordLink{
    text-decoration: underline;
    text-align: right;
    display: inline-block;
    width: 100%;
}
