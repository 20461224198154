.aboutModal {
    h2 {
        margin-bottom: 1.2rem;
        font: 2.4rem/2.9rem Times, serif;
        color: #000000;
    }

    h3 {
        margin-bottom: 1.2rem;
        font: 1.8rem/2.1rem Times, serif;
        text-align: center;
        color: #000000;
    }

    p {
        margin-bottom: 2rem;
    }

    a {
        color: #3fb867;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: 600;
    }
}