.buyMembershipPage {
  max-width: 60.2rem;
  padding-bottom: 60px;
  margin: 0 auto;
  font-weight: 400;
}

.page {
  max-width: 61.2rem;
  padding-bottom: 60px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
}
.page h1 {
  margin-bottom: 54px;
  line-height: 36px;
  font-size: 28px;
  font-weight: 900;
  color: #1e1e1e;
  text-align: center;
}
.section {
  position: relative;
  padding-left: 36px;
  padding-right: 12px;
  margin-top: 42px;
  counter-increment: item;
  &:before {
    content: counter(item);
    position: absolute;
    left: 0;
    top: -3px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px #ccd3d7 solid;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    background: white;
  }
  &[data-active="true"]:before {
    background-color: #1e1e1e;
    border-color: #1e1e1e;
    color: white;
  }
  &:after {
    content: "";
    position: absolute;
    left: 14px;
    top: 31px;
    bottom: -34px;
    border-left: 1px #ccd3d7 dotted;
  }
  &[data-course="false"]:after {
    display: none;
  }
  &[data-course="true"]:after {
    bottom: -68px;
  }
}
.section h2, h3 {
  
  line-height: 22px;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  color: #1e1e1e;
}
h3{
  line-height: 20px;
  font-size: 16px;
  font-weight: 800;
  margin-top: .5em;

}
.section p {
  margin-top: 4px;
  color: #777;
}
.section b {
  font-weight: 600;
  color: #1e1e1e;
}
.section p a {
  text-decoration: underline;
  color: #F5B5CF;
}

.options {
  padding-bottom: 8px;
  margin-top: 16px;
}
.options > button:not(:first-child) {
  margin-top: 12px;
}
.options > button {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 34px 16px 16px;
  border-radius: 10px;
  border: 1px #ccd3d7 solid;
  text-align: left;
  background: white url(../images/input-radio-unchecked.svg) no-repeat right 16px top 16px;
  background-size: 18px;
  &[data-selected="true"] {
    padding: 15px 33px 15px 15px;
    border: 2px #002538 solid;
    background-image: url(../images/input-radio-checked.svg);
    background-position: right 15px top 15px;
  }
}
.options > button > span > button{
  display: block;
  text-decoration: underline;

}
.options b {
  display: block;
  font-weight: 600;
}
.options span {
  display: block;
  margin-top: 6px;
  font-size: 14px;
  color: #666;
}

.placeSearch {
  padding: 10px 0 8px 0;
}
.placeInput {
  width: 100%;
  height: 48px;
  padding: 6px 20px 6px 44px;
  border-radius: 24px;
  border: 1px #ccd3d7 solid;
  font-weight: 400;
  background: white url(../images/magnifier.svg) left 16px center no-repeat;
  background-size: 20px;
  color: #1e1e1e;
}
.distanceSelector {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 4px;
  margin-top: 10px;
  border-radius: 24px;
  background: #f1f1f1;
}
.distanceSelector button {
  width: 25%;
  border-radius: 22px;
  font-weight: 600;
  &[data-selected="true"] {
    background: #002538;
    color: white;
  }
}


@media screen and (min-width: 768px) {
  .buyMembershipPage {
    padding-bottom: 64px;
    margin-bottom: 48px;
    font-size: 18px;
  }
  .section {
    position: relative;
    padding-left: 44px;
    padding-right: 20px;
    margin-top: 64px;
    &:before {
      top: -4px;
      width: 32px;
      height: 32px;
      line-height: 30px;
    }
    &:after {
      left: 16px;
      top: 36px;
      bottom: -52px;
      border-left: 1px #ccd3d7 dotted;
    }
  }
  .section h2 {
    line-height: 24px;
    font-size: 18px;
  }
  .options button {
    margin-top: 16px;
  }
  .options span {
    font-size: 16px;
  }
}
