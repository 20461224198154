@import '_reset.scss';
@import '_colors.scss';
@import '_foundation.scss';
@import '_icons.scss';
@import '_utils.scss';

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
