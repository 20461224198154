.container {
  position: relative;
}
.button {
  height: 40px;
  padding: 0 42px 0 18px;
  border-radius: 24px;
  border: 1px #ccd3d7 solid;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: white;
  color: #002538;
  transition: border-color 0.3s ease;

  @media screen and (max-width: 1023px) {
    max-width: 330px;
  }

  @media screen and (max-width: 539px) {
    max-width: 240px;
  }

  &:before {
    content: "";
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 6px;
    background: url(./chevron.svg) center center no-repeat;
    background-size: contain;
  }

  &:hover,
  &[data-status="open"] {
    border-color: #002538;
  }

  &[data-status="open"]:before {
    transform: translateY(-50%) rotate(180deg);
  }

  &[data-status="loading"]:before {
    right: 16px;
    width: 16px;
    height: 16px;
    background-image: url(./loading.gif);
  }
}
.name {
  font-weight: 900;
}
.dropdown {
  position: absolute;
  z-index: 10;
  top: 48px;
  padding: 10px 0;
  border-radius: 8px;
  border: 1px #ccd3d7 solid;
  box-shadow: rgba(0, 0, 0, 0.1) 0 9px 14px;
  background: white;
  color: #002538;
}
.option {
  position: relative;
  display: block;
  padding: 12px 32px 12px 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px #979797 solid;
  }

  &:hover {
    background: #f6f6f6;
  }

  &.active:before {
    border-color: #002538;
    background: #002538 url(./tick.svg) center center no-repeat;
    background-size: 11px;
  }
}
.type {
  display: block;
  font-weight: 900;
}
