.container {
  display: flex;
  margin: 1rem 0 4rem 0;
  justify-content: space-between;
  max-width: 960px;
}
.content,
.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content {
  
  flex: 1 1 550px;
  padding-right: 3.6rem;
}
.sectionTitle {
  margin-bottom: 0.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}
.title {
  margin-bottom: 1.2rem;
  line-height: 1.2;
  font-size: 4rem;
  font-weight: 900;
}
.description {
  line-height: 1.5;
}
.description b,
.description strong {
  font-weight: 700;
}
.description p:not(:last-child),
.description ul:not(:last-child) {
  margin-bottom: 1.6rem;
}
.downloadWrapper img{
  margin-right: 1rem;
  position: relative;
  display: inline-block;
  top: -2px;


}
.download {

  display: inline-block;
  text-decoration: underline;  
  margin: 1.5rem 0;

  font-weight: 800;

}


@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column-reverse;
  }
  .content,
  .downloadBox {
    width: 100%;
  }
}
