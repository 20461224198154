.courseUpdateWrapper {
    background-color: #FFFAF7;
    img {
      display: block;
      float: right;
      max-width: 20rem;
      margin: 3rem 2rem 0 2rem;
      border-radius: 50%;
    }
  }



.courseUpdate a {
    text-decoration: underline;
  }
  .courseUpdate h5 {
    margin-bottom: 4px;
    line-height: 1.375;
    font-weight: 900;
  }
  .courseUpdate h1 {
    margin-bottom: 16px;
    line-height: 1.343;
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
  }
  .courseUpdate p {
    line-height: 1.5;
    font-weight: 600;
  }
  

  .container {
    display: flex;
    margin: 1rem 0 4rem 0;
    justify-content: space-between;
    max-width: 960px;
  }
.content,
.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  
    flex: 1 1 550px;
    padding-right: 3.6rem;
  }


.title {
    margin-bottom: 1.2rem;
    line-height: 1.2;
    font-size: 4rem;
    font-weight: 900;
  }
  .sectionTitle {
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
  }



.description {
    line-height: 1.5;
  }
  .description b,
  .description strong {
    font-weight: 700;
  }
  .description p:not(:last-child),
  .description ul:not(:last-child) {
    margin-bottom: 1.6rem;
  }
  .downloadWrapper img{
    margin-right: 1rem;
    position: relative;
    display: inline-block;
    top: -2px;
  
  
  }
  .download {
  
    display: inline-block;
    text-decoration: underline;  
    margin: 1.5rem 0;
  
    font-weight: 800;
  
  }
  

@media screen and (max-width: 1024px) {
    .container {
      flex-direction: column-reverse;
    }
    .content,
    .downloadBox {
      width: 100%;
    }
  }
  