.fitnessTrackerFields,
.exerciseFields {
    input,
    select {
        margin-bottom: 1.6rem;
    }

    input:focus,
    select:focus {
        box-shadow: 0 0 0 0.3rem rgba(80, 103, 150, 0.3);
        border-color: #002538;
    }
}

.fitnessTrackerFields {
    .amount {
        width: 7.2rem;
        text-align: right;
    }
}

.exerciseFields {
    select {
        color: #000000;
        background: url('./arrow.svg') center right 1.2rem no-repeat;
        background-size: 1.4rem 1.4rem;
    }
}