.container {
  padding-top: 4.8rem;
}

.searchContainer {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 2em;
}

.appAdLayout {
  display: flex;
  max-width: 1024px;
  margin: 0 auto;
  flex-wrap: wrap;
  > .appAd {
    width: 300px;
    margin: 64px auto 0 auto;
    > .appLogo {
      span {
        margin-bottom: 8px;
        display: inline-block;
      }
      // background: #024C5C;
      // //#031C2A
      // //#0D5362
      background: linear-gradient(to right, #031c2a 0%, #0d5362 100%);
      color: white;
      padding: 1em;
      border-radius: 10px;
    }
    &[data-download="true"] {
      margin-top: 0;
    }
  }
}
.goldPage {
  padding-top: 1.2rem;
  padding-bottom: 10rem;
  width: calc(100vw - 4rem);
  max-width: 107rem;
  margin: 0 auto;
  padding-bottom: 3rem;
  min-height: calc(100vh - 20rem);
}

.cantbuy {
  margin-top: 5rem;
  padding: 3.2rem 6.4rem;
  margin-bottom: 10rem;
  font-weight: bold;
  border-radius: 1rem;
  text-align: center;
  background-color: #f24e4e;
  color: white;
}

@media screen and (min-width: 767px) {
  .cantbuy {
    padding: 2rem 3rem;
    border-radius: 0.5rem;
  }
  .container {
    padding-top: 2.4rem;
  }
  .intro {
    display: flex;
  }

  .intro .appDetails {
    flex: 1 1 500px;
  }
  .appDownload {
    padding-left: 1rem;
  }
}
.purchaseSection,
.section {
  margin-top: 3.6rem;
  max-width: 60.2rem;
  margin-left: auto;
  margin-right: auto;
}

.appDownload {
  padding-top: 2.8rem;
  margin-bottom: 4.8rem;
}
.appDownload div {
  margin-bottom: 0.4rem;
}
.appDownload a {
  display: inline-block;
  width: 15.8rem;
  margin-bottom: 1rem;
}

.purchaseSection > h2,
.section > h2 {
  margin-bottom: 0.4rem;
  text-transform: uppercase;
  font-size: 2.4rem;
  line-height: 1.25;
  font-weight: 900;
}

.intro {
  margin-top: 1em;
}
.intro .appPreTitle {
  font-size: 300%;
}
.intro h2 {
  font-size: 400%;
  font-weight: 800;
}

.intro ul {
  padding-left: 2.5rem;
}
.intro li {
  font-size: 2rem;
  margin: 1rem 0;
  list-style-type: disc;
}

.freeTrialInfo {
  margin-top: 4px;
  font-style: italic;
  //background-color: rgb(241, 202, 63);
  background-color: #def3e1;
  padding: 5px 15px 15px 15px;
  overflow: hidden;
  border-radius: 10px;

  flex-direction: column;

  position: relative;
  strong {
    display: block;
    font-size: 20px;
    margin-bottom: 8px;
    margin-top: 4px;
  }
  p {
    margin: 6px 0;
    font-size: 16px;
    color: #1e1e1e !important;
  }
  ul {
    padding-left: 2.5rem;
  }
  li {
    font-size: 1.5rem;
    margin: 1rem 0;
    list-style-type: disc;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
    -webkit-transform: -webkit-translateX(0);
  }

  100% {
    transform: translateX(-339px);
    -webkit-transform: -webkit-translateX(-350px);
  }
}

// .freeTrialInfo:before {
//   content: "Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå   Akkurat nå  ";
//   text-transform: uppercase;
//   // width: var(--size-breakingJustNow-width);
//   width: 140rem;

//   // font-family: var(--font-family-ui);
//   color: inherit;
//   white-space: nowrap;
//   font-weight: 900;
//   font-style: italic;
//   height: 2.4rem;
//   display: flex;
//   align-items: center;
//   animation: marquee 11s linear infinite;
//   letter-spacing: .5px;
//   padding-top: .23em;
//   will-change: transform;
//   font-size: 1rem;
//   }

.registration {
  max-width: 60.2rem;
  margin-left: auto;
  margin-right: auto;
}

/* general/utility styles */
strong {
  font-weight: 900;
}

.purchasesebutton {
  height: 4.8rem;
  width: 250px;
  border-radius: 28px;
  background-color: #2ea369;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: system-ui, "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-right: 4.8rem;
}

.purchasesebutton:disabled {
  border: 1px #ccd3d7 solid;
  background: repeating-linear-gradient(
    135deg,
    #fff,
    #fff 0.3rem,
    #ccd3d7 0.5rem,
    #ccd3d7 0.5rem
  );
  color: #c3ccd0;
  cursor: not-allowed;
}

.image-button {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.webcoursecategories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.webcoursecategory {
  position: relative;
  flex: 1 0 20rem;
}

.webcoursecategoryBadge {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0.6rem;
  width: 9.6rem;
  height: 3.5rem;
  border-top-right-radius: 0.5rem;
}
.webcoursecategoryBadge strong {
  position: absolute;
  top: -3.5rem;
  right: -4.4rem;
  display: block;
  height: 7rem;
  padding: 4.4rem 6.22rem 0 2rem;
  border-radius: 3.5rem;
  line-height: 1.25;
  font-size: 1.4rem;
  font-weight: 900;
  background: #2ea369;
  color: white;
}
.webcoursecategoryCard .button {
  display: block;
  width: calc(100% - 1.2rem);
  padding-top: 4.8rem;
  padding-bottom: 4.2rem;
  margin: 0 0.6rem 2.6rem 0.6rem;
  border-radius: 0.5rem;
  border: 1px #ccd3d7 solid;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0.9rem 1.4rem;
  line-height: 2.4rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #002538;
  cursor: pointer;
  transition: border-color 0.3s ease;
  text-align: center;
}
.webcoursecategoryCard .button:hover {
  border-color: #002538;
}
.webcoursecategoryCard .button span {
  display: block;
  line-height: 1.5;
  font-size: 2.4rem;
}
.webcoursecategoryCard .button span:before {
  content: "";
  position: relative;
  top: 0.2rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-right: 1.2rem;
  background: url(./img/input-radio-unchecked.svg) center center no-repeat;
  background-size: contain;
}
.webcoursecategoryCard .button strong {
  font-weight: 900;
}
.webcoursecategoryCard ul {
  padding: 0 2.4rem;
  cursor: auto;
}
.webcoursecategoryCard li {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 1.6rem;
  line-height: 1.375;
  color: #333;
}
.webcoursecategoryCard li:before {
  content: "";
  position: absolute;
  top: 0.6rem;
  left: 0;
  width: 1.4rem;
  height: 1.2rem;
  background: url(./img/checkmark-dark.svg) no-repeat;
  background-size: contain;
}
.webcoursecategory[data-selected="true"] .webcoursecategoryCard .button {
  border-color: #002538;
  box-shadow: none;
  background: #002538;
  color: white;
}
.webcoursecategory[data-selected="true"] .webcoursecategoryCard span:before {
  background-image: url(./img/input-radio-checked.svg);
}

@media screen and (max-width: 767px) {
  .webcoursecategories {
    display: block;
  }
  .webcoursecategory {
    width: 100%;
  }
  .webcoursecategoryBadge {
    right: 0;
    border-top-right-radius: 1rem;
  }
  .webcoursecategoryCard {
    padding-bottom: 2.8rem;
    margin-bottom: 1.6rem;
    border-radius: 1rem;
    border: 1px #ccd3d7 solid;
    box-shadow: rgba(0, 0, 0, 0.06) 0 0.9rem 1.4rem;
    transition: border-color 0.3s ease;
  }
  .webcoursecategoryCard .button {
    width: 100%;
    padding-bottom: 3rem;
    margin: 0;
    border: 0;
    box-shadow: none;
  }
  .webcoursecategories[data-selected="true"] .webcoursecategoryCard {
    border-color: #002538;
    box-shadow: none;
    background: #002538;
  }
  .webcoursecategories[data-selected="true"] .webcoursecategoryCard .button {
    background: none;
  }
  .webcoursecategories[data-selected="true"] .webcoursecategoryCard li {
    color: white;
  }
  .webcoursecategories[data-selected="true"] .webcoursecategoryCard li:before {
    background-image: url(./img/checkmark-light.svg);
  }
}
.goldTypeDesciption {
  display: block;
  width: calc(100% - 1.2rem);
  padding: 3rem;
  margin: 0 0.6rem 3.6rem 0.6rem;
  border-radius: 0.5rem;
  border: 1px #ccd3d7 solid;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0.9rem 1.4rem;
  line-height: 2.4rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #002538;
  cursor: pointer;
  transition: border-color 0.3s ease;
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
}
.goldTypeDesciption.offer {
  border: 1px #667c88 solid;
  background-color: #f9eb45;
}
.goldTypeDesciption.restricted {
  border: 1px #667c88 solid;
  background-color: #f24e4e;
  color: white;
}

.optionSection {
  max-width: 60.2rem;
  margin-left: auto;
  margin-right: auto;
}

.purchaseSection label {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}

.purchaseSection input[type="text"],
.purchaseSection select {
  width: 100%;
  height: 4.8rem;
  padding: 0 2.3rem;
  border-radius: 2.4rem;
  border: 1px #002538 solid;
  font-weight: 600;
  background: white;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.purchaseSection label span {
  position: absolute;
  top: 1.3rem;
  left: 2.4rem;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.5);
  transition: all 0.3s ease-in-out;
}
.purchaseSection input[type="text"]:focus + span,
.purchaseSection select:focus + span,
.purchaseSection input:not([value=""]) + span,
.purchaseSection select:not([data-option=""]) + span {
  top: -0.8rem;
  left: 3rem;
  padding: 0 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  background: white;
  color: #333;
}

.purchaseSection .autofillFix {
  visibility: hidden;
}
.purchaseSection input:disabled,
.purchaseSection input:disabled ~ span {
  opacity: 0.4;
}
@media screen and (min-width: 768px) {
  .purchaseSection {
    padding: 2rem 0 2rem 0;
  }

  .purchaseSection label {
    width: 48.7%;
  }
}
label.coupon {
  margin-bottom: 3rem;
}

.error {
  margin: -2.4rem 0 3rem;
  font-weight: 600;
  color: #f24e4e;
}

.appButtons a:first-child {
  margin-bottom: 16px;
}
.appButtons a,
.appButtons img {
  width: 100%;
}

.actionButtons {
  margin-bottom: 6.4rem;
}
.actionButtons a {
  display: block;
  padding: 0 36px;
  margin-bottom: 16px;
  border-radius: 24px;
  line-height: 48px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  background: #036775;
  color: white;
  &[data-button="secondary"] {
    background: #f5b5cf;
    color: #312537;
  }
  &:hover {
    background: #2ea369;
    color: white;
  }
}

.placeSection {
  position: relative;
  counter-increment: item;
  margin-bottom: 1em;
}

.placeSection h2,
h3 {
  line-height: 22px;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  color: #1e1e1e;
}

.placeSection button {
  font-weight: 600;
  text-decoration: underline;
}

.placeSearch {
  padding: 10px 0 8px 0;
}

.distanceSelector {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 4px;
  margin-top: 10px;
  border-radius: 24px;
  background: #f1f1f1;
}
.distanceSelector button {
  width: 25%;
  border-radius: 22px;
  font-weight: 600;
  &[data-selected="true"] {
    background: #002538;
    color: white;
  }
}

.overlay {
  position: fixed;
  z-index: 99;
  inset: 0;
  background: rgba(white, 0.35);
}
.modal {
  overflow: scroll;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 0;
  max-height: 800px;
  height: calc(100% - 50px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 400;
  background: white;
  box-shadow: 0 -10px 10px rgba(182, 182, 182, 0.5);
  transform: translateY(100vh);
  animation: 0.6s ease slidein forwards;
}

@keyframes slidein {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

.wrapper {
  max-width: 960px;
  padding: 24px 16px;
  margin: 0 auto;
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
  display: block;
  width: 42px;
  height: 42px;
  padding: 2px;
  border-radius: 50%;
  background: rgba(white, 0.85);
}

.courseDescriptionSection {
  position: relative;
  padding-left: 36px;
  padding-right: 12px;
  margin-top: 42px;
  counter-increment: item;
  &:before {
    content: counter(item);
    position: absolute;
    left: 0;
    top: -3px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px #ccd3d7 solid;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    background: white;
  }
  &[data-active="true"]:before {
    background-color: #1e1e1e;
    border-color: #1e1e1e;
    color: white;
  }
  &:after {
    content: "";
    position: absolute;
    left: 14px;
    top: 31px;
    bottom: -34px;
    border-left: 1px #ccd3d7 dotted;
  }
  &[data-course="false"]:after {
    display: none;
  }
  &[data-course="true"]:after {
    bottom: -68px;
  }
}
.courseDescriptionSection h2,
h3 {
  line-height: 22px;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  color: #1e1e1e;
}
.courseDescriptionSection h3 {
  line-height: 20px;
  font-size: 16px;
  font-weight: 800;
  margin-top: 1em;
}
.courseDescriptionSection p {
  margin-top: 4px;
  color: #777;
}
.courseDescriptionSection b {
  font-weight: 600;
  color: #1e1e1e;
}
.courseDescriptionSection p a {
  text-decoration: underline;
  color: #f5b5cf;
}

.optionsCourseDescription {
  padding-bottom: 8px;
  margin-top: 16px;
}
.optionsCourseDescription > button:not(:first-child) {
  margin-top: 12px;
}
.optionsCourseDescription > button {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 34px 16px 16px;
  border-radius: 10px;
  border: 1px #ccd3d7 solid;
  text-align: left;
  background: white url(./img/input-radio-unchecked.svg) no-repeat right 16px
    top 16px;
  background-size: 18px;
  &[data-selected="true"] {
    padding: 15px 33px 15px 15px;
    border: 2px #002538 solid;
    background-image: url(./img/input-radio-checked.svg);
    background-position: right 15px top 15px;
  }
}
.optionsCourseDescription > button > span > button {
  display: block;
  text-decoration: underline;
}
.optionsCourseDescription b {
  display: block;
  font-weight: 600;
}
.optionsCourseDescription span {
  display: block;
  margin-top: 6px;
  font-size: 14px;
  color: #666;
}
