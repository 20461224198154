.search {
  position: relative;
  padding: 2.4rem 1.6rem;
  border-bottom: 1px #e5e9eb solid;

  @media screen and (max-width: 639px) {
    padding-right: 6rem;
  }
}

.searchInput {
  z-index: 1;
  width: 100%;
  height: 4.8rem;
  padding: 0 4.8rem 0 1.4rem;
  border-radius: 0.6rem;
  border: 1px #e5e9eb solid;
  font-size: 16px;
  background-color: transparent;

  &:focus {
    border-color: #002538;
    box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
  }
}

.searchIcon {
  position: absolute;
  z-index: -1;
  right: 1.6rem;
  top: 2.4rem;
  width: 4.8rem;
  line-height: 4.8rem;
  font-size: 2.2rem;
  text-align: center;
  color: #8b8b8b;

  @media screen and (max-width: 639px) {
    right: 6rem;
  }
}
