.overlay {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(black, 0.5);
}

.modal {
  position: relative;
  z-index: 51;
  box-shadow: 0 2px 6px 0 rgba(black, 0.3);
  background: white;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: 639px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media screen and (min-width: 640px) {
    position: relative;
    width: 100%;
    max-width: 50rem;
    height: 90vh;
    max-height: 834px;
    border-radius: 0.6rem;
  }
}

.modalHeader {
  position: relative;
  height: 61px;
  line-height: 61px;
  border-bottom: 1px #e5e9eb solid;

  @media screen and (max-width: 639px) {
    height: 0;
    border: 0;
  }
}

.modalTitle {
  padding: 0 1.6rem;
  font-size: 1.8rem;
  color: #000000;

  @media screen and (max-width: 639px) {
    display: none;
  }
}

.modalClose {
  position: absolute;
  top: 16px;
  right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  font-size: 2rem;
  line-height: 3.2rem;
  color: #3fb867;

  @media screen and (max-width: 639px) {
    z-index: 1;
    top: 2.4rem;
    right: 1.4rem;
    height: 4.8rem;
    font-size: 2.2rem;
    line-height: 4.8rem;
  }
}

.modalBody {
  position: relative;
  height: calc(100%);

  @media screen and (min-width: 640px) {
    height: calc(100% - 61px);
  }
}
