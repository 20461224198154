html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    @media screen and (max-width: 374px) { font-size: 53.34%; }

    @media screen and (min-width: 375px) { font-size: 62.5%; }
}

body {
    font-family: system-ui, 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.25;
    color: $color-body-text;
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-y: scroll;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
    vertical-align: middle;
}

a {
    color: inherit;
}

textarea,
button,
input,
select {
    overflow: visible;
    padding: 0;
    margin: 0;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    color: inherit;
}

button {
    overflow: visible;
    cursor: pointer;
    background-color: transparent;
    cursor: pointer;
}

input {
    color: $color-title-blue;
}

textarea,
input,
select {
    appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

input:required {
    box-shadow: none;
}

::-webkit-input-placeholder {
  color: $color-body-text-weak;
}
::-moz-placeholder {
  color: $color-body-text-weak;
}
:-ms-input-placeholder {
  color: $color-body-text-weak;
}
