.feedback {
  display: flex;
  margin-right: -138px;
}
.content {
  padding-right: 75px;
  flex-grow: 1;
}
.instructor {
  display: flex;
  max-width: 320px;
  flex-direction: column;
  align-items: center;
}
.instructor img {
  width: 82%;
  margin-bottom: 36px;
  border-radius: 50%;
}
.contact {
  width: 310px;
  padding: 24px 22px;
  border-radius: 5px;
  border: 1px #ccd3d7 solid;
}
.contact h3 {
  margin-bottom: 12px;
  line-height: 1.25;
  font-size: 24px;
  font-weight: 900;
}
.contact p {
  margin-bottom: 40px;
  line-height: 1.5;
  color: black;
}
.contact a {
  display: block;
  border-radius: 20px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  background: #002538;
  color: white;
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .feedback {
    margin-right: -60px;
  }
}

@media screen and (max-width: 1024px) {
  .feedback {
    margin-right: 0;
  }
  .content {
    padding-right: 56px;
  }
  .contact {
    width: 260px;
  }
}

@media screen and (max-width: 767px) {
  .feedback {
    flex-direction: column;
  }
  .content {
    padding-right: 0;
    margin-bottom: 42px;
  }
  .contact {
    width: auto;
  }
}
