.dietTables {
  position: relative;
  display: flex;
  margin: -4rem 0 4rem 0;
  font-size: 1.6rem;
  transition: opacity 0.3s ease;

  &[data-loading="true"] {
    opacity: 0.5;
  }

  @media screen and (min-width: 768px) {
    overflow: hidden;
  }

  @media screen and (min-width: 1024px) {
    margin: -5.4rem 0 5.4rem 0;
  }
}

.dietTablesMsg {
  position: relative;
  padding: 1.2rem 1.6rem 1.2rem 4.8rem;
  margin: -4rem 0 4rem 0;
  border: 1px #f1c40f solid;
  border-radius: 0.6rem;
  line-height: 1.35;
  font-size: 1.5rem;
  background: rgba(#f1c40f, 0.2);
  $color: #000000;

  i {
    font-size: 2rem;
    position: absolute;
    left: 1.6rem;
    top: 1.3rem;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.dietTable {
  tr {
    border-bottom: 1px #e5e9eb solid;

    &:first-child {
      border-bottom: 2px #002538 solid;
    }

    &:nth-child(8) {
      border-width: 2px;
    }

    &:last-child {
      border: 0;
    }

    &:nth-child(9),
    &:nth-child(10) {
      font-weight: 600;
      background: #f1f8ff;
      color: #000000;
    }
  }

  th {
    font-weight: 600;
    color: #000000;
  }

  th,
  td {
    padding: 1.2rem 1.2rem;
    white-space: nowrap;
  }

  a {
    color: #002538;
  }
}

.dietTable[data-table="days"] {
  width: 16rem;

  th {
    text-align: left;
  }
}

.dietTable[data-table="data"] {
  width: 100%;

  th:not(:last-child),
  td:not(:last-child) {
    text-align: right;
  }

  th:not(:last-child) {
    width: 6rem;
    min-width: 6rem;

    @media screen and (min-width: 1024px) {
      width: 14rem;
      min-width: 14rem;
    }
  }

  th:last-child {
    text-align: left;
  }
}

.dietTableComments {
  display: block;
  max-width: 40rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dietTableWrapper {
  width: calc(100% - 16rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
