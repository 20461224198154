$border-color: #f1c40f;
$bg-color: rgba($border-color, 0.2);
$color: #000000;

.systemMessages {
  max-width: 1170px;
  margin: 0 16px;

  @media screen and (min-width: 768px) {
      margin: 0 24px;
  }

  @media screen and (min-width: 1210px) {
      margin: 0 auto;
  }
}

.systemMessage {
  position: relative;
  padding: 2rem 1.6rem;
  margin-top: 3.6rem;
  border: 1px $border-color solid;
  border-radius: 0.6rem;
  color: $color;
  background: $bg-color;

  &-dismiss {
    position: absolute;
    top: 1.8rem;
    right: 1.4rem;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    font-size: 1.3rem;
    line-height: 3.2rem;
    text-align: center;
    background: rgba($color, 0.2);
  }

  &-title {
    padding-right: 4rem;
    margin-bottom: 1.6rem;
    font: 2.4rem/1.2 Times, serif;
  }

  &-content {
    line-height: 1.5;
  }

  p:not(:last-child),
  ol:not(:last-child),
  ul:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
