.ProfileWrapper {
  margin: 40px 0;

  a{
    text-decoration: underline;
  }

  .ItemWrapper {
    
    margin: 0 auto;
    position: relative;
    margin-bottom: 2.5rem;
    



    .ItemHeader {
      width: 100%;
      padding: 0;
      font-size: 2rem;
      font-weight: 600;
      border-bottom: 1px solid #aaa;
      margin-bottom: .2rem;
      display: flex;
      justify-content: space-between;

      .ItemAction {

        position: relative;
        top: 4rem;
      }
      a{
        text-decoration: underline;
      }

      .ItemAction:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }

    .ItemBody {
      text-align: left;
      padding: 0;
      font-size: 1em;
      h4{
        font-size: 1.7rem;
        font-weight: 600;
        margin-top: 2rem;
  
      }
      .TextContainer {
        width: 100%;
        margin-bottom: 20px;

        .TextHeader {
          font-style: italic;

        }

        .TextBody {
          padding: 10px 0;
          font-weight: 500;
          text-align: justify;
        }
      }

      .TableItem {

        .ColItem {
          display: inline-flex;
          padding: .2rem 0;
          margin-right: 10px;

          &.ItemLabel {
            font-style: italic;
          }

          &.ItemValue {
            font-weight: 600;
          }
        }
      }

      .TextWrapper {
        padding: 6px 0;
      }
    }
  }

  .partner_request {
    font-weight: 500;
    transition: opacity 0.3s ease;
  }

  .partner_request:hover {
    opacity: 0.6;
  }

  .checkItem {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;

    .energyCalculationValue {
      font-size: 14px;
      color: #8b8b8b;

      strong {
        font-size: 20px;
        font-weight: 600;
        color: #4b4b4b;
      }
    }
  }
}
