.workoutSummary {
    padding: 0.4rem 0;
    text-align: center;

    a {
        color: #002538;
    }

    section {
        padding: 2.4rem 1.6rem;
        margin-bottom: 2rem;
        border-radius: 0.6rem;
        font-size: 1.4rem;
        line-height: 1.5;
        background: #f4f6f7;

        &:last-child {
            padding-bottom: 3.2rem;
            margin-bottom: 0;
        }
    }

    h3 {
        margin-bottom: 2rem;
        font: 2.1rem/1.5 Times, serif;
        color: #000000;
    }

    p {
        padding: 1.6rem 0 0.4rem;
        margin-top: 2rem;
        border-top: 1px #e9e9e9 solid;
        font-size: 1.6rem;
    }

    strong {
        position: relative;
        top: 0.1rem;
        font-size: 1.6rem;
        font-weight: 600;
        color: #000000;
    }

    i {
        display: inline-block;
        padding: 1.6rem;
        margin: 0 1.1rem 1.2rem 1.1rem;
        border-radius: 50%;
        font-size: 4.8rem;
        background: white;
        color: #e5e9eb;

        &[data-completed="true"] {
            color: #3fb575;
        }
    }

    span {
        display: block;
        padding: 1.2rem 0 2.4rem 0;
        color: #8b8b8b;
    }

    em {
        white-space: nowrap;
    }
}

@media screen and (min-width: 1024px) {
    .workoutSummary {
        display: flex;
        justify-content: space-between;
        padding: 0;

        section {
            width: calc(33.33% - 1.6rem);
            margin-bottom: 0;
        }
    }
}

.scoreList {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;

    li {
        width: 50%;

    }
}