.container {
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  line-height: 1.5;
}

.amount {
  flex: 1;
}

.amountText {
  font-weight: 600;
}

@media screen and (min-width: 425px) {
  .modal {
    font-size: 1.5rem;
  }
}
