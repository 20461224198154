@font-face {
  font-family: 'Simple-Line-Icons-Pro';
  src:  url('../fonts/Simple-Line-Icons-Pro.eot?4g9qhg');
  src:  url('../fonts/Simple-Line-Icons-Pro.eot?4g9qhg#iefix') format('embedded-opentype'),
    url('../fonts/Simple-Line-Icons-Pro.ttf?4g9qhg') format('truetype'),
    url('../fonts/Simple-Line-Icons-Pro.woff?4g9qhg') format('woff'),
    url('../fonts/Simple-Line-Icons-Pro.svg?4g9qhg#Simple-Line-Icons-Pro') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Simple-Line-Icons-Pro';
  src:  url('../fonts/Simple-Line-Icons-Pro.eot?ucnkjt');
  src:  url('../fonts/Simple-Line-Icons-Pro.eot?ucnkjt#iefix') format('embedded-opentype'),
    url('../fonts/Simple-Line-Icons-Pro.ttf?ucnkjt') format('truetype'),
    url('../fonts/Simple-Line-Icons-Pro.woff?ucnkjt') format('woff'),
    url('../fonts/Simple-Line-Icons-Pro.svg?ucnkjt#Simple-Line-Icons-Pro') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Simple-Line-Icons-Pro' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gr-comfort-food:before {
  content: "\e800";
}
.icon-arrow-1-circle-left:before {
  content: "\e801";
}
.icon-arrow-1-circle-right:before {
  content: "\e802";
}
.icon-gr-dairy:before {
  content: "\e803";
}
.icon-gr-exercise:before {
  content: "\e804";
}
.icon-arrow-1-left:before {
  content: "\e805";
}
.icon-arrow-1-right:before {
  content: "\e806";
}
.icon-gr-fruit:before {
  content: "\e807";
}
.icon-gr-vegetables:before {
  content: "\e808";
}
.icon-gr-steps:before {
  content: "\e809";
}
.icon-gr-water:before {
  content: "\e80a";
}
.icon-gr-good:before {
  content: "\e80b";
}
.icon-gr-heart-friendly-food:before {
  content: "\e80c";
}
.icon-gr-nuts:before {
  content: "\e80d";
}
.icon-gr-obs-food:before {
  content: "\e80e";
}
.icon-gr-ok:before {
  content: "\e80f";
}
.icon-gr-yes-food:before {
  content: "\e810";
}
.icon-arrow-2-left:before {
  content: "\e811";
}
.icon-arrow-2-right:before {
  content: "\e812";
}
.icon-gr-bad:before {
  content: "\e813";
}
.icon-gr-omega-3:before {
  content: "\e814";
}
.icon-gr-some-food:before {
  content: "\e815";
}
.icon-gr-strength:before {
  content: "\e816";
}
.icon-email:before {
  content: "\e86d";
}
.icon-printer:before {
  content: "\e8cc";
}
.icon-star-o:before,
.icon-star-empty:before {
  content: "\e900";
}
.icon-star:before {
  content: "\e901";
}
.icon-cart-1:before {
  content: "\e903";
}
.icon-list-bullets:before {
  content: "\e9c2";
}
.icon-spell-check:before {
  content: "\e9d7";
}
.icon-align-bottom:before {
  content: "\e9ea";
}
.icon-cancel-circle:before {
  content: "\e9f2";
}
.icon-check-circle-2:before {
  content: "\e9f5";
}
.icon-check-square:before {
  content: "\e9f8";
}
.icon-check:before {
  content: "\e9f9";
}
.icon-close:before {
  content: "\e9fa";
}
.icon-config-1:before {
  content: "\e9fb";
}
.icon-hamburger-menu-1:before {
  content: "\ea20";
}
.icon-info:before {
  content: "\ea26";
}
.icon-magnifier-1:before {
  content: "\ea38";
}
.icon-minus-circle:before {
  content: "\ea3d";
}
.icon-more-circles-horizontal:before {
  content: "\ea40";
}
.icon-more-circles-vertical:before {
  content: "\ea41";
}
.icon-more-squares-vertical-filled:before {
  content: "\ea42";
}
.icon-outside:before {
  content: "\ea48";
}
.icon-plus-circle1:before {
  content: "\ea52";
}
.icon-question:before {
  content: "\ea57";
}
.icon-reload:before {
  content: "\ea5c";
}
.icon-settings:before {
  content: "\ea62";
}
.icon-share-3:before {
  content: "\ea66";
}
.icon-trash:before {
  content: "\ea78";
}
.icon-warning-circle:before {
  content: "\ea7c";
}
.icon-file-copy:before {
  content: "\ea8e";
}
.icon-bottle-milk:before {
  content: "\eaec";
}
.icon-carrot:before {
  content: "\eaf5";
}
.icon-chef-hat-1:before {
  content: "\eaf9";
}
.icon-fork-knife-2:before {
  content: "\eb14";
}
.icon-glass-water:before {
  content: "\eb21";
}
.icon-meal-time:before {
  content: "\eb33";
}
.icon-pear-apple:before {
  content: "\eb39";
}
.icon-stop:before {
  content: "\eba6";
}
.icon-facebook:before {
  content: "\ecbd";
}
.icon-google-plus:before {
  content: "\ecc7";
}
.icon-instagram:before {
  content: "\eccd";
}
.icon-rss:before {
  content: "\ecdb";
}
.icon-twitter:before {
  content: "\ece3";
}
.icon-youtube:before {
  content: "\eced";
}
.icon-calendar:before {
  content: "\ecf8";
}
.icon-clock:before {
  content: "\ecfa";
}
.icon-emoticon-grin:before {
  content: "\ed9f";
}
.icon-emoticon-smile:before {
  content: "\eda0";
}
.icon-fire-1:before {
  content: "\eda6";
}
.icon-user-circle:before {
  content: "\edfd";
}
.icon-heart:before {
  content: "\ee32";
}
.icon-link-3:before {
  content: "\ee69";
}
.icon-back-7:before {
  content: "\e82c";
}
.icon-graduation-cap:before {
  content: "\e94c";
}
.icon-pencil2:before {
  content: "\e982";
}
.icon-edit-1:before {
  content: "\ea0b";
}
.icon-plus-circle:before {
  content: "\ea51";
}
.icon-ID-card:before {
  content: "\ede6";
}
