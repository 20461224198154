
.registerSteps {
    p {
        position: relative;
        padding-left: 2.6rem;
        line-height: 1.5;

        i {
            position: absolute;
            top: 0.3rem;
            left: 0;
            font-size: 1.8rem;
            color: #777f90;
        }
    }

    fieldset {
        padding-top: 1.6rem;
        padding-bottom: 0.4rem;
        margin-top: 1.8rem;
        border-top: 1px #e9e9e9 solid;
    }

    label {
        display: block;
        margin-bottom: 0.8rem;
    }

    input {
        width: 8rem;
        height: 4rem;
        padding: 0.4rem 1.2rem;
        margin-right: 0.6rem;
        border: 1px #e5e9eb solid;
        border-radius: 0.4rem;
        text-align: right;
        color: #000000;

        &:focus {
            border-color: #002538;
            box-shadow: 0 0 0 0.3rem rgba(#002538, 0.3);
        }
    }

    button {
        height: 4rem;
        padding: 0 1.6rem;
        margin-right: 1rem;
        border-radius: 0.4rem;
        line-height: 4rem;
        background: #002538;
        color: white;

        &:hover {
            background: #5f7bb3;
        }
    }

    .icon-check-circle-2 {
        font-size: 2.4rem;
        line-height: 4rem;
        color: #3fb575;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &[data-processing="true"] {
        pointer-events: none;

        input,
        button {
            opacity: 0.5;
        }
    }

    &[data-success="true"] {
        .icon-check-circle-2 { animation: success 3s; }

        @keyframes "success" {
            0% { opacity: 0; }
            25% { opacity: 1; }
            85% { opacity: 1; }
            100% { opacity: 0; }
        }
    }
}