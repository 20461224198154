.PageBody {
    // min-height: calc(100vh - 116px - 237px);
    //min-height: 80rem;
    min-height: calc(100vh - 341px);
    transition: opacity 0.5s ease;
    padding-top: 78px;

    // @media screen and (min-width: 375px) {
    //     min-height: calc(100vh - 116px - 285px);
    // }

    // @media screen and (min-width: 375px) {
    //     min-height: calc(100vh - 116px - 285px);
    // }

    @media screen and (max-width: 767px) {
        padding-top: 58px;
    }

    @media screen and (max-width: 374px) {
        padding-top: 50px;
    }

    // @media screen and (min-width: 960px) {
    //     min-height: calc(100vh - 116px - 287px);
    // }

    &[data-status="loading"] {
        opacity: 0;
    }

    &[data-status="loading"] {
        opacity: 0;
    }

    &[data-status="offline"],
    &[data-status="online"] {
        opacity: 1;
    }
}

