.container {
  max-width: 682px;
}
.container h2 {
  margin-bottom: 22px;
  line-height: 1.375;
  font-size: 32px;
  font-weight: 800;
  color: black;
}
.container p {
  color: black;
}
.container > p {
  line-height: 1.5;
  margin-bottom: 12px;
}
.container > p a,
.container > p b {
  font-weight: 700;
}
.container > p a {
  text-decoration: underline;
}

.focus ol {
  padding: 26px 26px 26px 16px;
  margin: 26px 0;
  border-radius: 8px;
  background: #fcf7f1;
  color: black;
  counter-reset: item;
}
.focus ol li {
  position: relative;
  padding-left: 56px;
  line-height: 1.5;
  counter-increment: item;
}
.focus ol li:not(:last-child) {
  margin-bottom: 48px;
}
.focus ol li:before {
  content: counter(item);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 30px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 900;
  border-right: 1px solid black;
}

.files p {
  line-height: 1.5;
  margin-bottom: 28px;
}
.files li {
  padding: 1px 0 1px 34px;
  margin-bottom: 16px;
  line-height: 22px;
  background: url(../images/document-icon.png) left center no-repeat;
  background-size: 22px;
}
.files li a {
  font-weight: 900;
  text-decoration: underline;
}
