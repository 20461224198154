.pac-container {
  border-radius: 10px;
  font-family: system-ui, "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.pac-item {
  border-top: 0;
  border-bottom: 1px solid #d9d9d9;
  font-size: 16px;
  color: #888;
}
.pac-item-query {
  font-size: 16px;
  padding: 10px;
  color: #1e1e1e;
}
.pac-matched {
  font-weight: 600;
}
