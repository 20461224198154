.missingWeightMsg {
    position: relative;
    padding: 1.4rem 1.6rem 1.4rem 4.6rem;
    margin-bottom: 1.2rem;
    border: 1px solid #f1c40f;
    border-radius: 0.6rem;
    line-height: 1.5;
    background: rgba(#f1c40f, 0.15);
    color: #000000;

    a {
        color: #3fb867;
    }

    i {
        position: absolute;
        top: 1.6rem;
        left: 1.6rem;
        font-size: 2rem;
        color: #3fb867;
    }
}