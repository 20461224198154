.questions {
  margin-top: 6rem;
}
.question {
  display: flex;
  margin: 2rem 0;
}
.question form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

}
.question textarea {
  width: 100%;
  padding: 0.5rem;
  line-height: 1.5;
  background: transparent;
}

.question[data-max="warning"] textarea {
  border: 1px solid #f24e4e
}
.question[data-max="warning"] .maxWarning{
  color: #f24e4e;

}
.question button {
  height: 3rem;
  padding: 0.2rem 3.9rem 0.2rem 2.1rem;
  margin: 0.8rem 1rem;
  border-radius: 1.5rem;
  font-size: 1.4rem;
  font-weight: 800;
  background: #2ea369 url(../images/submit.svg) center right 0.5rem no-repeat;
  background-size: 2.2rem;
  color: white;
}
.question form[data-loading="true"] {
  opacity: 0.8;
  pointer-events: none;
}
.question form[data-loading="true"] button {
  pointer-events: none;
  background-image: url(../images/ajax-loader.gif);
  background-position: center right 0.6rem;
  background-size: 2rem;
}
.question h5 {
  position: relative;
  top: -0.4rem;
  margin-bottom: -0.4rem;
  color: black;
}
.question blockquote {
  
  font-weight: 900;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.questionLabel{
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  border: 1.5px solid #002538;
  border-radius: 50%;
  margin-right: 3rem;
  color: #002538;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2.8rem;
  position: relative;

}
.questionLabel:after{
  content: " ";
  height: 1.1rem;
  width: 1.1rem;
  background-color: #FF982A;
  position: absolute;
  right: -.3rem;
  top: -.3rem;
  border-radius: 50%;
}

.questionLabel[data-started="true"]:after{
  content: " ";
  height: 1.1rem;
  width: 1.1rem;
  background-color: #00b75d;
  position: absolute;
  right: -.3rem;
  top: -.3rem;
  border-radius: 50%;
}


.questionWrapper{
  flex: 1 1 auto;
  
}
