$color-roede-green: #008766;
$color-roede-green-hover: #fc354b;
$color-roede-blue: #002538;
$color-roede-blue-weak: #ccd3d7;
$color-dark-blue: $color-roede-blue;
$color-title-blue: #036675;
$color-footer-text: white;
$color-body-text: #000;
$color-body-text-weak: #667c88;
$color-outlines: #ccd3d7;
$color-white: #ffffff;
$color-offwhite: #fefaf7;
$color-black: #000000;
$color-light-blue: #ccd3d7;
$color-success: #00b75d;
$color-kitchen-blue: $color-roede-blue;
