.addCustomFood {
  padding: 1.6rem 1.6rem;
  border-top: 1px #e5e9eb solid;
  text-align: center;
  background: white;

  @media screen and (min-width: 640px) {
    position: sticky;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.addCustomFoodButton {
  width: 100%;
  max-width: 34.3rem;
  border-radius: 0.6rem;
  line-height: 4.8rem;
  background: #002538;
  color: white;

  i {
    margin-right: 0.6rem;
    font-size: 2rem;
  }
}