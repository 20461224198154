.authPage {
    min-height: calc(100vh - 116px - 237px);
    padding: 4.2rem 0 5.4rem 0;
    margin: 0 16px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.35;

    @media screen and (min-width: 375px) {
        min-height: calc(100vh - 116px - 285px);
    }

    @media screen and (min-width: 375px) {
        min-height: calc(100vh - 116px - 285px);
    }

    @media screen and (min-width: 768px) {
        min-height: calc(100vh - 116px - 243px);
        margin: 0 24px;
        padding: 5.4rem 0 6.8rem 0;
    }

    @media screen and (min-width: 960px) {
        min-height: calc(100vh - 116px - 287px);
    }

    section {
        width: 100%;
        max-width: 343px;
        margin: 0 auto;

        &:first-child {
            padding-bottom: 4.2rem;
            margin-bottom: 3.6rem;
            border-bottom: 1px #e5e9eb solid;
        }
    }

    h1, h2 {
        margin-bottom: 2.4rem;
        font-size: 2.35rem;
        font-weight: 900;
        color: #000000;
    }

    .callToAction {
        height: 4.8rem;
        width: 35rem;
        border-radius: 28px;
        background-color: #008766;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-family: system-ui, 'Open Sans', Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        margin: 2rem auto;
        transition: all 0.3s ease-in-out;
    }



     @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 842px;
        margin-right: auto;
        margin-left: auto;

        section {
            max-width: 421px;
        }

        section:first-child {
            padding-bottom: 0;
            padding-right: 78px;
            border-right: 1px #e5e9eb solid;
            border-bottom: 0;
        }

        section:last-child {
            height: 409px;
            padding-left: 78px;
        }
     }
}

.authLogin {
    p {
        margin-bottom: 1.6rem;
    }
}

.authSignup {
    ul {
        padding-top: 0.6rem;
        margin-bottom: 2.4rem;
    }

    li {
        position: relative;
        padding-left: 1.6rem;
        margin-bottom: 0.4rem;

        &:before {
            content: "-";
            position: absolute;
            left: 0.6rem;
            color: #000000;
        }
    }

    p {
        text-align: center;
        margin-bottom: 1.6rem;
    }
}

.loginFields {
    margin-bottom: 2.4rem;

    label,
    span {
        display: block;
    }

    span {
        margin-bottom: 0.6rem;
    }

}

.toggleLogin {
    margin-top: 24px;
    text-decoration: underline;
    color: #3fb867;
}
.toggleLogin:hover {
    text-decoration: none;
}
.authPage .field {
    margin-right: 0;
    margin-left: 0;
}
.loginFields {
    margin-bottom: 0;
}
.loginFields button {
    width: 100%;
}
.authPage strong {
    font-weight: 700;
}
.authLogin,
.authSignup {
    align-self: flex-start;
}
.authLogin form {
    margin-top: 0;
}
