button[type=submit] {
  height: 4.8rem;
  width: 35rem;
  border-radius: 28px;
  background-color: #008766;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: system-ui, "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin: 2rem auto;
  transition: all 0.3s ease-in-out;
}

.button {

  font-size: 1.4rem;
  width: 7rem;
  border: 1px solid #cecece;
  height: 3.4rem;
  border-radius: 1.6rem;

}


.button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.overlay {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  .modal {
    z-index: 51;
    background: white;
    margin: 1rem;


    .modalHeader {
      position: relative;
      height: 61px;
      line-height: 61px;
      border-bottom: 1px #e5e9eb solid;

      .modalTitle {
        text-align: center;
        color: #000000;
        font-size: 2rem;
        font-weight: 600;
      }

      .modalClose {
        position: absolute;
        top: 16px;
        right: 0;
        width: 3.2rem;
        height: 3.2rem;
        font-size: 2rem;
        line-height: 3.2rem;
        color: #3fb867;
      }
    }

    .modalBody {
      position: relative;
      overflow: auto;
      overflow-x: hidden;

      .modalContainer {
        width: 100%;
        padding: 1rem 0;
      }
      ul{
        padding-left: 4rem;
        margin-top: 2rem;
        list-style: disc;
      }

      .saveButton[data-processing="true"] {
        opacity: 0.6;
        pointer-events: none;
      }
      h3{
        font-size: 1.4em;
        font-weight: 700;
        margin-top: 1.5em;

      }
    }

    .goalsItems {
      padding: 10px 20px;
      border: 1px solid #dddddd;
      border-radius: 8px;
      margin: 0 auto 20px auto;
      color: #000;
      text-align: center;
      cursor: pointer;
      width: 80%;

      .goalTitle {
        font-size: 1.4em;
        font-weight: 400;
      }
      .goalText {
        font-size: 1.1em;
        font-weight: 400;
      }
    }

    .goalsItemsActive {
      padding: 10px 20px;
      border: 1px solid #dddddd;
      border-radius: 8px;
      margin: 0 auto 20px auto;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      color: #fff;
      text-align: center;
      background: #9eee89;
      background: linear-gradient(to right, #9eee89 0%, #206a75 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9eee89', endColorstr='#206a75',GradientType=1 );
      cursor: pointer;
      width: 80%;

      .goalTitle {
        font-size: 1.4em;
        font-weight: 400;
      }
      .goalText {
        font-size: 1.1em;
        font-weight: 400;
      }
    }

    .selectedItemWrapper {
      .selectedItemCenter {
        text-align: center;
        padding: 20px 0;
      }

      .selectedItemActive {
        padding: 8px 20px;
        display: inline-flex;
        color: #fff;
        background: #388766;
        border: 1px solid #dddddd;
        border-radius: 25px;
        cursor: pointer;
        margin: 0 10px 10px 0;
      }

      .selectedItem {
        padding: 8px 20px;
        display: inline-flex;
        color: #4d5c62;
        border: 1px solid #dddddd;
        border-radius: 25px;
        cursor: pointer;
        margin: 0 10px 10px 0;
      }

      .titleWrapper {
        text-align: center;
        width: 100%;
        font-size: 1.0em;
      }
    }

    .modalContainer {
      .scrollBarWrapper {
          margin: 25px auto;

          .titleWrapper {
            font-size: 1.2em;
            text-align: center;
            margin-bottom: 5px;
          }

          .detailsWrapper {
            font-size: 1.0em;
            text-align: left;
          }
      }
    }

    .inputContainer {
      width: 100%;
      padding: 20px 0;
      display: flex;
      margin: 2rem 0;
      padding-right: 2rem;
      .questionLabel{
        flex: 0 0 auto;
        width: 3rem;
        height: 3rem;
        border: 1.5px solid #002538;
        border-radius: 50%;
        margin-right: 3rem;
        color: #002538;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 2.8rem;
        position: relative;

      }
      .questionLabel:after{
        content: " ";
        height: 1.1rem;
        width: 1.1rem;
        background-color: #FF982A;
        position: absolute;
        right: -.3rem;
        top: -.3rem;
        border-radius: 50%;
      }


      .labelContainer {

        font-weight: 900;
        font-size: 1.8rem;
        line-height: 3.8rem;
        color: black;
        margin-right: 2rem;

        flex: 0 0 auto;
      }

      .questionLabel{
        flex: 0 0 auto;
        width: 3rem;
        height: 3rem;
        border: 1.5px solid #002538;
        border-radius: 50%;
        margin-right: 3rem;
        color: #002538;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 2.8rem;
        position: relative;

      }
      .questionLabel:after{
        content: " ";
        height: 1.1rem;
        width: 1.1rem;
        background-color: #FF982A;
        position: absolute;
        right: -.3rem;
        top: -.3rem;
        border-radius: 50%;
      }

      .inputWrapper {
          width: 100%;
          position: relative;


          .labelStyle {
            font-weight: 900;
            font-size: 1.8rem;
            position: relative;
            top: -0.4rem;
            margin-bottom: -0.4rem;
            color: black;
          }

          .inputDescription {

            margin-top: 0.6rem;
            margin-bottom: 0.6rem;
            text-align: justify;
            color: black;
            font-weight: 600;
            font-size: 1.6rem;
          }

          .inputItems {
            width: 100%;
            padding: 6px 40px 6px 0px;
            border-bottom: 1px solid #ddd;
            color: #0c222b;
          }
          .inputMultiple {
            display: flex;
          }
          .inputItemMultiple {
            padding: 6px 6px 6px 0px;
            border-bottom: 1px solid #ddd;
            color: #0c222b;
            margin-left: 2rem;
            flex: 1 0 auto;
            display: flex;
          }
          .inputItemMultiple input{
            flex: 1 0 auto;
          }
          .inputIconWrapperMultiple{
            flex: 0 0 auto;
          }

          .inputIconWrapper {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }

          .inputText {
            width: 100%;
            height: 4.8rem;
            padding: 0 1.4rem 0 1.4rem;
            border-radius: 0.6rem;
            border: 1px #e5e9eb solid;
            font-size: 16px;
            background-color: transparent;

          }

          textarea {
            width: 100%;
            padding: 0 1.4rem 0 1.4rem;
            border-radius: 0.6rem;
            border: 1px #e5e9eb solid;
            font-size: 16px;
            background-color: transparent;
            resize: none;
            height: 10rem;
          }

          .input {
            width: 100%;

            padding: 0.6rem 1.4rem 0.6rem 1.4rem;
            border-radius: 0.6rem;
            border: 1px #e5e9eb solid;
            font-size: 16px;
            background-color: transparent;
            color: #111;
          }
          textarea.input {
            width: 100%;
            padding: 0.5rem;
            line-height: 1.5;
            background: transparent;
            border: none;
          }
      }
    }



    .kcalPerDay {
      text-align: center;
      font-size: 1.4em;
      font-weight: 400;
    }

    .modalFooter {
      padding: 1.6rem 1.6rem;
      border-top: 1px #e5e9eb solid;
      text-align: center;
      background: white;

      .saveButton {
        width: 100%;
        max-width: 34.3rem;
        border-radius: 0.6rem;
        line-height: 4.8rem;
        background: #002538;
        color: white;
      }
    }

  }

  .newsletterWrapper {
    padding: 10px 0px;

    .newsletterItem {
      -webkit-appearance: checkbox;
      -moz-appearance: checkbox;
      appearance: checkbox;
    }
  }
}

@media screen and (max-width: 639px) {
  .modal {
    position: absolute!important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .modalContainer {
    width: 100%;
  }
}
@media screen and (min-width: 640px) {
  .modal {
    position: relative;
    max-width: 550px;
    width: 100%;
    max-height: 834px;
    border-radius: 0.6rem;
  }

  .modalContainer {
    width: 100%;
  }
}


.bmiContainer {
  width: 100%;

  .bmiWrapper {
    text-align: center;
    margin: 20px 0;

    .bmiResults {
      display: inline-flex;
      border: 1px solid #ddd;
      border-radius: 25px;
      overflow: hidden;

      .bmiItem {
        padding: 10px 20px;
        font-size: 1.2em;

        &:first-child {
          border-right: 1px solid #ddd;
        }

        &.goodBMI {
          background: rgba(4,104,117,1);
          background: -moz-linear-gradient(left, rgba(4,104,117,1) 0%, rgba(4,104,117,1) 38%, rgba(116,202,131,1) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgba(4,104,117,1)), color-stop(38%, rgba(4,104,117,1)), color-stop(100%, rgba(116,202,131,1)));
          background: -webkit-linear-gradient(left, rgba(4,104,117,1) 0%, rgba(4,104,117,1) 38%, rgba(116,202,131,1) 100%);
          background: -o-linear-gradient(left, rgba(4,104,117,1) 0%, rgba(4,104,117,1) 38%, rgba(116,202,131,1) 100%);
          background: -ms-linear-gradient(left, rgba(4,104,117,1) 0%, rgba(4,104,117,1) 38%, rgba(116,202,131,1) 100%);
          background: linear-gradient(to right, rgba(4,104,117,1) 0%, rgba(4,104,117,1) 38%, rgba(116,202,131,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#046875', endColorstr='#74ca83', GradientType=1 );

          color: #fff;
        }

        &.blackBMI {
          background: #002137;
          color: #fff;
        }

        &.badBMI {
          background: rgba(242,78,78,1);
          background: -moz-linear-gradient(left, rgba(242,78,78,1) 0%, rgba(242,78,78,1) 23%, rgba(247,142,72,1) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgba(242,78,78,1)), color-stop(23%, rgba(242,78,78,1)), color-stop(100%, rgba(247,142,72,1)));
          background: -webkit-linear-gradient(left, rgba(242,78,78,1) 0%, rgba(242,78,78,1) 23%, rgba(247,142,72,1) 100%);
          background: -o-linear-gradient(left, rgba(242,78,78,1) 0%, rgba(242,78,78,1) 23%, rgba(247,142,72,1) 100%);
          background: -ms-linear-gradient(left, rgba(242,78,78,1) 0%, rgba(242,78,78,1) 23%, rgba(247,142,72,1) 100%);
          background: linear-gradient(to right, rgba(242,78,78,1) 0%, rgba(242,78,78,1) 23%, rgba(247,142,72,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f24e4e', endColorstr='#f78e48', GradientType=1 );

          color: #fff;
        }

      }

      .bmiCenter {
        width: 100%;
        text-align: center;


      }
    }
  }
}

.buttonOverride {
  display: block;
  text-decoration: underline;
  margin-top: 16px;
}