.DateNavigation {
    position: relative;
    margin-bottom: 3.6rem;
    border-top: 1px solid #e5e9eb;
    border-bottom: 1px solid #e5e9eb;
    line-height: 4.8rem;

    &__prev,
    &__next {
        position: absolute;
        top: 0;
        font-size: 2.4rem;
        color: #002538;
    }

    &__next {
        right: 0;
    }

    &__weekday {
        display: none;
    }

    &__content {
        font-size: 1.5rem;
        text-align: center;
    }

    &__sep {
        padding: 0 1.4rem 0 1.2rem;
        color: #ddd;
    }

    &__button {
        color: #002538;
    }

    &__button-icon {
        position: relative;
        margin-right: 0.4rem;
        font-size: 1.8rem;
    }
}

@media screen and (min-width: 768px) {

    .DateNavigation {
        margin-top: 1.4rem;
        margin-bottom: 5.4rem;

        &__weekday {
            display: inline;
        }

        &__content{
            font-size: 1.8rem;
        }

        &__sep {
            padding: 0 2.4rem 0 2.2rem;
        }

        &__button-icon {
            font-size: 2rem;
        }
    }
}



.calendar-overlay {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.5);
}

.calendar {
    position: absolute;
    z-index: 101;
    left: 50%;
    width: 30rem;
    padding: 1.6rem;
    margin: -1.2rem 0 0 -15rem;
    border: 1px solid #e5e9eb;
    border-radius: 0.6rem;
    box-shadow: 0 2px 6px 0 rgba(black, 0.3);
    text-align: center;
    background-color: white;
}

.calendar-header {
    overflow: hidden;
    margin-bottom: 1.2rem;
    font-size: 1.8rem;
    line-height: 2.8rem;

    strong {
        font-weight: 600;
    }

    button {
        float: left;
        display: block;
        padding: 0 0.8rem;
        border: 1px solid #ddd;
        border-radius: 0.4rem;
        color: #002538;

        @media (hover: hover) {
            &:hover {
                border-color: #e5e9eb;
            }
        }

        &:last-child {
            float: right;
        }
    }
}

.calendar-weekdays {
    overflow: hidden;
    margin-bottom: 0.8rem;

    li {
        float: left;
        width: 14.285%;
        font-size: 1.4rem;
        color: #777;
    }
}

.calendar-days {
    overflow: hidden;
    padding: 0 0 1px 1px;

    li {
        float: left;
        width: calc(14.285% + 1px);
        margin: 0 0 -1px -1px;
        border: 1px solid #ddd;
        font-size: 1.6rem;
        line-height: 3.6rem;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                background-color: #ddd;
            }
        }

        &.today {
            background-color: #e5e9eb;
        }

        &.selected {
            color: white;
            background-color: #002538;
        }

        &.empty {
            border-color: white;
            cursor: default;
        }


    }
}

.calendar-month,
.calendar-years {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 100%;

  li {
    margin: 0 0 -1px -1px;
    border: 1px solid #ddd;
    font-size: 1.6rem;
    line-height: 3.6rem;
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            background-color: #ddd;
        }
    }

    &.today {
        background-color: #e5e9eb;
    }

    &.selected {
        color: white;
        background-color: #002538;
    }

    &.empty {
        border-color: white;
        cursor: default;
    }
  }
}


.calendar-overlay,
.calendar {
    display: block;
}
