
    .unpaidInvoices {
        min-width: 100%;
        padding-left: 3.6rem;
        padding-top: 2.6rem;
      }
  
  
  
      .unpaidInvoice {
        position: relative;
        width: 100%;
        text-align: center;
        font-size: 17px;
        margin-top: 10px;
        margin-top: 10px;
        padding: 16px 0;
        color: #fff;
        border-radius: 0.55rem;
        background: #026675 linear-gradient(90deg, #cb1b4a 0%, #ee6e73 100%);
      }
  
     .unpaidInvoice a {
        font-weight: 700;
      }
  