.navigation {
  position: relative;
  margin-bottom: 60px;
}
.navigation:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50%;
  left: 0;
  background: white;
}
.wrapper {
  position: relative;
  max-width: 1218px; // 1170px + left and right padding
  padding: 0 24px;
  margin: 0 auto;
}
.buttonPrev, .buttonNext {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 28px;
  height: 28px;
  margin-top: -14px;
  border-radius: 50%;
  box-shadow: 0 6px 16px 2px rgba(0,0,0,0.4);
  text-indent: -1000vw;
  overflow: hidden;
  background: white url(../images/chevron-left.svg) center center no-repeat;
  background-size: 7px;
}
.buttonPrev {
  left: 20px;
}
.buttonNext {
  right: 20px;
  background-image: url(../images/chevron-right.svg);
}
.navLinksScroller {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}
.navLinks {
  display: flex;
  padding: 20px 0; // space for navLink shadow
}
.navLink {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  min-width: 140px;
  height: 90px;
  padding: 0 8px;
  margin-left: 24px;
  border-radius: 1rem;
  border: 1px #fefefe solid;
  box-shadow: 0 5px 14px 2px rgba(black, 0.09);
  line-height: 1.375;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  background: #fefefe;
  color: #002538;
}
.navLink:hover {
  box-shadow: 0 3px 8px 1px rgba(black, 0.09);
}
.navLink:first-child {
  margin-left: 0;
}
.sectionStatus {
  display: block;
  line-height: 2.9rem;
  font-size: 1.4rem;
  font-weight: 400;
}
.navLink[tabindex="-1"] {
  pointer-events: none;
}
.navLink[tabindex="-1"] .sectionName {
  color: #aab1b6;
}

.navLink[data-active="true"] {
  border-color: #ff982a;
  background: #ffeedb;
  box-shadow: none;
}
.navLink[data-active="true"]:after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 10px;
  background: url(../images/section-active.svg) center bottom no-repeat;
}

.navLink[data-status="done"][data-active="false"] {
  box-shadow: none;
  border-color: #ccd3d7;
}

.navLink[data-status="locked"] .sectionStatus {
  padding-left: 2.3rem;
  background: url(../images/section-status-locked.svg) center left no-repeat;
  background-size: 1.6rem;
}
.navLink[data-status="done"] .sectionStatus {
  padding-left: 3.1rem;
  font-weight: 800;
  background: url(../images/section-status-done.svg) center left no-repeat;
  background-size: 2rem;
  color: #2ea369;
}
.navLink[data-status="ongoing"] .sectionStatus {
  padding-left: 2.9rem;
  font-weight: 800;
  background: url(../images/section-status-ongoing.svg) center left no-repeat;
  background-size: 2rem;
  color: #ff982a;
}
.navLink[data-status="open"] .sectionStatus {
  padding-left: 2.9rem;
  background: url(../images/section-status-open.svg) center left no-repeat;
  background-size: 2rem;
}

.wrapper[data-navbuttons="true"] {
  padding: 0;
}
.wrapper[data-navbuttons="true"] .navLinks:after {
  content: "\00a0 ";
  font-size: 1px;
  margin-left: 20px;
}
.wrapper[data-navbuttons="true"] .navLink:first-child {
  margin-left: 20px;
}
.wrapper[data-navbuttons="true"] .buttonPrev,
.wrapper[data-navbuttons="true"] .buttonNext {
  display: block;
}

@media screen and (min-width: 768px) {
  .wrapper[data-navbuttons="true"] .navLinks:after,
  .wrapper[data-navbuttons="true"] .navLink:first-child {
    margin-left: 24px;
  }
}
