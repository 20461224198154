.overlay {
  position: fixed;
  z-index: 99;
  inset: 0;
  background: rgba(white, 0.35);
}
.modal {
  overflow: scroll;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 0;
  max-height: 800px;
  height: calc(100% - 50px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 400;
  background: white;
  box-shadow: 0 -10px 10px rgba(182, 182, 182, 0.5);
  transform: translateY(100vh);
  animation: 0.6s ease slidein forwards;
}
@keyframes slidein {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

.wrapper {
  max-width: 960px;
  padding: 24px 16px;
  margin: 0 auto;
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
  display: block;
  width: 42px;
  height: 42px;
  padding: 2px;
  border-radius: 50%;
  background: rgba(white, 0.85);
}

.course {
  padding: 32px 0;
  border-bottom: 1px #ccd3d7 solid;
}
.courseName {
  font-size: 24px;
  font-weight: 900;
}
.courseLocation {
  margin-top: 16px;
  padding-left: 21px;
  background: url(../images/marker.svg) left center no-repeat;
  background-size: 15px;
  a {
    text-decoration: underline;
  }
}
.courseLocation[data-type='web'] {
  padding-left: 25px;
  background: url(../images/web.svg) left center no-repeat;
  background-size: 16px;
  a {
    text-decoration: none;
    pointer-events: none;
  }
}
.courseLocation[data-type='video'] {
  padding-left: 26px;
  background: url(../images/video.svg) left center no-repeat;
  background-size: 18px;
  a {
    text-decoration: none;
    pointer-events: none;
  }
}
.courseTime {
  display: flex;
  margin-top: 8px;
  svg {
    width: 16px;
    margin-right: 5px;
  }
}
.courseTimes {
  margin-top: 12px;
  label {
    display: inline-block;
    margin: 8px 8px 0 0;
  }
  label span {
    display: inline-block;
    padding: 0 18px 0 38px;
    border-radius: 22px;
    border: 1px #ccd3d7 solid;
    line-height: 44px;
    font-variant: tabular-nums;
    background: url(../images/input-radio-unchecked.svg) left 14px center no-repeat;
    background-size: 17px;
    cursor: pointer;
  }
  input:checked + span {
    border: 2px #002538 solid;
    line-height: 42px;
    background-image: url(../images/input-radio-checked.svg);
  }
}
.courseSubmit {
  display: block;
  padding: 0 24px;
  margin-top: 16px;
  border-radius: 24px;
  line-height: 48px;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(101deg, #036775, #9eef89);
  color: #fff;
  &:hover {
    background: #2ea369;
  }
}

.instructor {
  padding: 32px 0;
}
.instructorHeader {
  position: relative;
  min-height: 100px;
  padding: 4px 0 4px 110px;
}
.instructorHeader img {
  position: absolute;
  left: 0;
  top: 0;
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 6px;
  background: #ccd3d7;
}
.instructorHeader h3 {
  font-size: 20px;
  font-weight: 900;
}
.instructorHeader p {
  color: #777;
}
.instructorBio ul,
.instructorBio p {
  margin-top: 24px;
  line-height: 1.375;
}
.instructorBio ul {
  list-style: disc;
  padding-left: 15px;
}

.aboutCourse {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 900;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding: 32px 24px;
  }
}

@media screen and (min-width: 1024px) {
  .intructorAndCourseType {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .courseType {
    padding-top: 36px;
    padding-left: 36px;
  }
}
