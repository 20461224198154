.container {
  margin: 0.2rem 0 1.4rem 0;
}

.progressContainer {
  border-radius: 0.4rem;
  background-color: #DCDCDC;
}

.progressBar {
  height: 0.8rem;
  border-radius: 0.4rem;
  background: white;
}

.goodProgress {
  background: linear-gradient(to left, #9EEF89 0%, #026675 100%);
}

.badProgress {
  background: linear-gradient(to left, #F24E4E 0%, #F89C46 100%);
}
