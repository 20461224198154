

.messaging {
  &.str-chat {
    .str-chat__header-livestream{
      box-shadow: inherit;
    }
  }
}

.str-chat__header-livestream {
  box-shadow: inherit;
}

.user-info-container {
  position: relative;
  z-index: 2;
  background: var(--white-snow);
  box-shadow: 0 7px 9px 0 var(--border), 0 1px 0 0 var(--border);
  padding: var(--xs-p) var(--xl-p);
  // display: flex;
  font-family: var(--second-font);

  a {
    font-weight: 600;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.6;
    }
  }

  .user-general-info-container {
    display: grid;
    grid-template-columns: 1fr 40px;
    gap: 1em;

    .toggle-wrapper {
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 1;
      }
    }
  }

  .user-details-wrapper {
    line-height: 25px;
    padding: 12px 0;
  }  
}
